import { IIcon } from "./interface"

export const ChatIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M16.6665 2.16669H3.33317C2.4165 2.16669 1.6665 2.91669 1.6665 3.83335V18.8334L4.99984 15.5H16.6665C17.5832 15.5 18.3332 14.75 18.3332 13.8334V3.83335C18.3332 2.91669 17.5832 2.16669 16.6665 2.16669ZM16.6665 13.8334H4.99984L3.33317 15.5V3.83335H16.6665V13.8334Z"
        fill="#646464"
      />
    </svg>
  )
}
