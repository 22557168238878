import { IIcon } from "./interface"

export const CloseTransparentIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1878 5L0 9.1878L0.812201 10L5 5.8122L9.1878 10L10 9.1878L5.8122 5L10 0.812201L9.1878 0L5 4.1878L0.812201 0L0 0.812201L4.1878 5Z"
      />
    </svg>
  )
}
