import { IIcon } from "./interface"

export const CloseIconGlobal: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" className={className}>
      <path
        d="M6.1377 7.67993L3.99951 5.54199L1.86133 7.67993C1.76041 7.78167 1.64065 7.8623 1.5083 7.91724C1.37595 7.97217 1.23363 8.00037 1.09033 8C0.947031 8.00037 0.805206 7.97217 0.672852 7.91724C0.540497 7.8623 0.420256 7.78167 0.319336 7.67993C0.218044 7.57871 0.13783 7.45846 0.0830078 7.32617C0.0281853 7.19388 0 7.05214 0 6.90894C0 6.76573 0.0281853 6.62399 0.0830078 6.4917C0.13783 6.35941 0.218044 6.23916 0.319336 6.13794L2.45752 4L0.319336 1.86206C0.218044 1.76084 0.13783 1.64059 0.0830078 1.5083C0.0281853 1.37601 0 1.23427 0 1.09106C0 0.947863 0.0281853 0.80612 0.0830078 0.673828C0.13783 0.541536 0.218044 0.421294 0.319336 0.320068C0.420561 0.218776 0.541048 0.138319 0.67334 0.0834961C0.805632 0.0286736 0.947131 0.000488281 1.09033 0.000488281C1.23353 0.000488281 1.37552 0.0286736 1.50781 0.0834961C1.6401 0.138319 1.7601 0.218776 1.86133 0.320068L3.99951 2.45801L6.1377 0.320068C6.23901 0.218746 6.35884 0.138332 6.49121 0.0834961C6.62359 0.02866 6.7659 0.000488281 6.90918 0.000488281C7.05246 0.000488281 7.19429 0.02866 7.32666 0.0834961C7.45903 0.138332 7.57935 0.218746 7.68066 0.320068C7.78196 0.421294 7.86217 0.541536 7.91699 0.673828C7.97181 0.80612 8 0.947863 8 1.09106C8 1.23427 7.97181 1.37601 7.91699 1.5083C7.86217 1.64059 7.78196 1.76084 7.68066 1.86206L5.54248 4L7.68066 6.13794C7.78196 6.23916 7.86217 6.35941 7.91699 6.4917C7.97181 6.62399 8 6.76573 8 6.90894C8 7.05214 7.97181 7.19388 7.91699 7.32617C7.86217 7.45846 7.78196 7.57871 7.68066 7.67993C7.57953 7.78165 7.45917 7.86231 7.32666 7.91724C7.19415 7.97216 7.05214 8.00034 6.90869 8C6.76542 8.00021 6.62354 7.97216 6.49121 7.91724C6.35888 7.86231 6.23872 7.78153 6.1377 7.67993Z"
        fill="#222222"
      />
    </svg>
  )
}
