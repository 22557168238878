import { IIcon } from "./interface"

export const ScheduleIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" className={className}>
      <path
        d="M9.4915 2C4.7995 2 1 5.808 1 10.5C1 15.192 4.7995 19 9.4915 19C14.192 19 18 15.192 18 10.5C18 5.808 14.192 2 9.4915 2ZM9.5 17.3C5.743 17.3 2.7 14.257 2.7 10.5C2.7 6.743 5.743 3.7 9.5 3.7C13.257 3.7 16.3 6.743 16.3 10.5C16.3 14.257 13.257 17.3 9.5 17.3ZM9.925 6.25H8.65V11.35L13.1125 14.0275L13.75 12.982L9.925 10.7125V6.25Z"
        fill="currentColor"
      />
    </svg>
  )
}
