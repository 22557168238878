import { IIcon } from "./interface"

export const AvatarIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" className={className}>
      <g id="Avatar">
        <circle id="Circle" cx="28" cy="28" r="27.5" fill="white" stroke="#EEEEEE" />
        <path
          id="Vector"
          d="M32.4561 28.8512C33.6384 27.9261 34.5013 26.6576 34.9249 25.222C35.3485 23.7865 35.3116 22.2553 34.8195 20.8416C34.3273 19.4278 33.4043 18.2018 32.1788 17.3341C30.9534 16.4663 29.4864 16 27.9821 16C26.4778 16 25.0109 16.4663 23.7854 17.3341C22.56 18.2018 21.637 19.4278 21.1448 20.8416C20.6526 22.2553 20.6157 23.7865 21.0393 25.222C21.4629 26.6576 22.3259 27.9261 23.5081 28.8512C21.4823 29.6584 19.7146 30.9972 18.3937 32.7249C17.0727 34.4525 16.2479 36.5043 16.0073 38.6615C15.9899 38.819 16.0038 38.9784 16.0483 39.1305C16.0928 39.2827 16.167 39.4246 16.2667 39.5482C16.468 39.7979 16.7607 39.9578 17.0805 39.9928C17.4004 40.0277 17.7211 39.9349 17.9721 39.7348C18.2232 39.5346 18.3839 39.2435 18.4191 38.9254C18.6839 36.581 19.808 34.4158 21.5765 32.8434C23.345 31.2711 25.6341 30.4019 28.0062 30.4019C30.3784 30.4019 32.6674 31.2711 34.436 32.8434C36.2045 34.4158 37.3285 36.581 37.5933 38.9254C37.6261 39.2201 37.7675 39.4922 37.9902 39.6894C38.2129 39.8865 38.5011 39.9946 38.7993 39.9928H38.9319C39.248 39.9566 39.537 39.7976 39.7357 39.5505C39.9345 39.3034 40.027 38.9882 39.9931 38.6735C39.7513 36.5102 38.9221 34.4531 37.5944 32.7228C36.2666 30.9925 34.4904 29.6541 32.4561 28.8512ZM27.9821 27.9997C27.0281 27.9997 26.0955 27.7183 25.3022 27.1912C24.509 26.6641 23.8907 25.9148 23.5256 25.0383C23.1605 24.1617 23.065 23.1971 23.2511 22.2665C23.4372 21.336 23.8966 20.4812 24.5712 19.8103C25.2458 19.1394 26.1054 18.6825 27.0411 18.4974C27.9768 18.3123 28.9466 18.4073 29.8281 18.7704C30.7095 19.1335 31.4628 19.7483 31.9929 20.5372C32.5229 21.3261 32.8058 22.2536 32.8058 23.2024C32.8058 24.4747 32.2976 25.6949 31.393 26.5946C30.4884 27.4943 29.2614 27.9997 27.9821 27.9997Z"
          fill="#26B571"
        />
      </g>
    </svg>
  )
}
