import { IIcon } from "./interface"

export const AlertTriangle: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" className={className}>
      <path
        d="M13.7191 5.14676L2.42572 24.0001C2.19288 24.4033 2.06967 24.8605 2.06837 25.3261C2.06707 25.7917 2.18771 26.2496 2.41829 26.6541C2.64887 27.0587 2.98135 27.3958 3.38266 27.6319C3.78397 27.868 4.24012 27.995 4.70572 28.0001H27.2924C27.758 27.995 28.2141 27.868 28.6154 27.6319C29.0167 27.3958 29.3492 27.0587 29.5798 26.6541C29.8104 26.2496 29.931 25.7917 29.9297 25.3261C29.9284 24.8605 29.8052 24.4033 29.5724 24.0001L18.2791 5.14676C18.0414 4.7549 17.7067 4.43092 17.3073 4.20607C16.9079 3.98122 16.4574 3.8631 15.9991 3.8631C15.5407 3.8631 15.0902 3.98122 14.6908 4.20607C14.2914 4.43092 13.9567 4.7549 13.7191 5.14676Z"
        fill="#FFF7E6"
        stroke="#FFB200"
        strokeWidth="2.74854"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16 12V17.3333" stroke="#FFB200" strokeWidth="2.74854" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 22.6667H16.0125" stroke="#FFB200" strokeWidth="2.74854" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
