import { IIcon } from "./interface"

export const ArrowDownIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="#CCCCCC" className={className}>
      <g clipPath="url(#clip0_7114_34264)">
        <path
          d="M1.41657 1.00005L4.00324 3.58672L6.5899 1.00005C6.8499 0.740054 7.2699 0.740054 7.5299 1.00005C7.7899 1.26005 7.7899 1.68005 7.5299 1.94005L4.4699 5.00005C4.2099 5.26005 3.7899 5.26005 3.5299 5.00005L0.469902 1.94005C0.209902 1.68005 0.209902 1.26005 0.469902 1.00005C0.729902 0.74672 1.15657 0.740054 1.41657 1.00005Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_7114_34264">
          <rect width="8" height="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
