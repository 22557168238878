import { IIcon } from "./interface"
import { cx } from "class-variance-authority"

export const ComboboxChevronDownIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={cx("absolute right-4 top-1/2 h-6 w-6 -translate-y-1/2 transform transition-all duration-500 ease-in-out", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.12461 8.99999L12.0046 12.88L15.8846 8.99999C16.2746 8.60999 16.9046 8.60999 17.2946 8.99999C17.6846 9.38999 17.6846 10.02 17.2946 10.41L12.7046 15C12.3146 15.39 11.6846 15.39 11.2946 15L6.70461 10.41C6.31461 10.02 6.31461 9.38999 6.70461 8.99999C7.09461 8.61999 7.73461 8.60999 8.12461 8.99999Z"
        fill="#222222"
      />
    </svg>
  )
}
