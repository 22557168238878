import { IIcon } from "./interface"

export const ForwardArrowIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#7A7A7A" className={className}>
      <path
        d="M5.20898 13H16.379L11.499 17.88C11.109 18.27 11.109 18.91 11.499 19.3C11.889 19.69 12.519 19.69 12.909 19.3L19.499 12.71C19.889 12.32 19.889 11.69 19.499 11.3L12.919 4.69997C12.529 4.30997 11.899 4.30997 11.509 4.69997C11.119 5.08997 11.119 5.71997 11.509 6.10997L16.379 11H5.20898C4.65898 11 4.20898 11.45 4.20898 12C4.20898 12.55 4.65898 13 5.20898 13Z"
        fill="currentColor"
      />
    </svg>
  )
}
