import React from "react"
import { IIcon } from "../interface"

export const BuildingIcon7: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="44" viewBox="0 0 20 44" fill="none" className={className}>
      <path
        d="M1 43H19M7 30H8M7 34H8M7 38H8M12 30C12 30 12.6095 30 13 30M12 34H13M12 38H13M3 43V3C3 2.46957 3.21071 1.96086 3.58579 1.58579C3.96086 1.21071 4.46957 1 5 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V43M12 26H13M7 26H8M12 22H13M7 22H8M12 18H13M7 18H8M12 14H13M7 14H8M12 10H13M7 10H8M12 6H13M7 6H8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
