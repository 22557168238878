import { IIcon } from "./interface"

export const MyMarketIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="78" height="20" viewBox="0 0 78 20" fill="none" className={className}>
      <path
        d="M6.91966 18.4093H62.849V1.94199H6.25926L2.97432 4.2013L1.26956 8.13694V11.0395L3.44189 15.7105L6.91966 18.4093Z"
        fill="white"></path>
      <path
        d="M55.5556 12.4459C55.5718 12.4775 55.5818 12.5121 55.585 12.5478C55.5882 12.5834 55.5846 12.6194 55.5742 12.6535C55.5639 12.6877 55.5471 12.7193 55.5249 12.7467C55.5026 12.774 55.4753 12.7965 55.4446 12.8127C55.0535 13.0156 54.6225 13.1218 54.1853 13.1228C52.9686 13.1122 52.3713 12.3999 52.3713 11.0567V8.41461H51.8594C51.8243 8.41509 51.7895 8.40826 51.757 8.39454C51.7244 8.38082 51.6949 8.36047 51.6701 8.33471C51.6453 8.30894 51.6257 8.27828 51.6125 8.24453C51.5993 8.21077 51.5927 8.17461 51.5931 8.13818V7.49494C51.5927 7.45859 51.5993 7.42251 51.6125 7.38885C51.6257 7.35519 51.6454 7.32464 51.6702 7.29902C51.695 7.27339 51.7246 7.25322 51.7571 7.23969C51.7896 7.22616 51.8244 7.21956 51.8594 7.22028H52.3713V6.23149C52.3747 6.16334 52.401 6.09856 52.4455 6.0484C52.4901 5.99823 52.5502 5.96584 52.6153 5.95683L53.7211 5.83102C53.757 5.82593 53.7936 5.82893 53.8283 5.83981C53.863 5.85069 53.895 5.8692 53.9222 5.89408C53.9494 5.91896 53.9711 5.94963 53.9859 5.984C54.0007 6.01837 54.0081 6.05564 54.0078 6.09328V7.22028H54.9822C55.0195 7.22181 55.056 7.23174 55.0893 7.24939C55.1225 7.26704 55.1517 7.29198 55.1747 7.32248C55.1978 7.35299 55.2142 7.38833 55.2228 7.42606C55.2314 7.4638 55.232 7.50302 55.2245 7.54101L55.1358 8.18425C55.1243 8.24856 55.0916 8.30672 55.0434 8.34875C54.9951 8.39078 54.9343 8.41406 54.8713 8.41461H54.0078V11.0336C54.0078 11.583 54.1784 11.7797 54.5385 11.7797C54.6705 11.7795 54.801 11.7518 54.9225 11.6981C54.9831 11.6733 55.0504 11.6726 55.1115 11.6962C55.1726 11.7197 55.2232 11.7659 55.2535 11.8257L55.5556 12.4459Z"
        fill="#414042"></path>
      <path
        d="M15.0768 8.74654V12.645C15.0782 12.681 15.0726 12.717 15.0604 12.7508C15.0483 12.7845 15.0297 12.8154 15.0059 12.8417C14.982 12.8679 14.9534 12.889 14.9216 12.9036C14.8898 12.9182 14.8556 12.926 14.8209 12.9267H13.7048C13.6698 12.9272 13.6351 12.9204 13.6027 12.9066C13.5703 12.8929 13.5408 12.8725 13.5162 12.8467C13.4915 12.8209 13.4721 12.7902 13.459 12.7565C13.446 12.7227 13.4396 12.6866 13.4403 12.6503V9.04601C13.4403 8.43644 13.2407 8.27519 12.9745 8.27519C12.6434 8.27519 12.3789 8.54985 12.1571 9.0212V12.6503C12.155 12.7226 12.1265 12.7914 12.0774 12.8427C12.0282 12.894 11.9622 12.924 11.8926 12.9267H10.7629C10.6957 12.9226 10.6328 12.8914 10.5875 12.8397C10.5423 12.7881 10.5182 12.7201 10.5206 12.6503V9.04601C10.5206 8.43644 10.2987 8.27519 10.0342 8.27519C9.69294 8.27519 9.43697 8.54985 9.19294 9.0212V12.6503C9.19527 12.7204 9.17101 12.7886 9.12537 12.8403C9.07973 12.892 9.01637 12.923 8.94892 12.9267H7.82436C7.75473 12.924 7.68868 12.894 7.63958 12.8427C7.59047 12.7914 7.56197 12.7226 7.55986 12.6503V7.4831C7.56197 7.41078 7.59047 7.342 7.63958 7.29068C7.68868 7.23936 7.75473 7.20933 7.82436 7.20666H8.77657C8.83755 7.20602 8.89649 7.22945 8.94148 7.27222C8.98646 7.31498 9.01413 7.37389 9.01888 7.43702L9.10762 7.89598C9.28393 7.62712 9.5216 7.40778 9.79941 7.25751C10.0772 7.10724 10.3865 7.03072 10.6998 7.03478C10.9799 7.01991 11.2573 7.09767 11.4922 7.25687C11.7271 7.41607 11.9073 7.64852 12.0069 7.92078C12.4489 7.32362 12.9557 7.03478 13.6417 7.03478C14.5052 7.03478 15.0802 7.66739 15.0802 8.74654"
        fill="#414042"></path>
      <path
        d="M29.3407 8.74645V12.6449C29.3414 12.6812 29.3351 12.7173 29.322 12.7511C29.309 12.7848 29.2896 12.8155 29.2649 12.8413C29.2402 12.8671 29.2108 12.8875 29.1784 12.9012C29.146 12.915 29.1112 12.9218 29.0762 12.9213H27.9704C27.9353 12.9218 27.9005 12.915 27.868 12.9012C27.8355 12.8875 27.806 12.8672 27.7812 12.8414C27.7564 12.8156 27.7368 12.785 27.7236 12.7512C27.7103 12.7175 27.7038 12.6813 27.7042 12.6449V9.04592C27.7042 8.43635 27.5046 8.2751 27.2384 8.2751C26.9073 8.2751 26.6428 8.54976 26.421 9.02111V12.6502C26.4188 12.7225 26.3903 12.7913 26.3412 12.8426C26.2921 12.8939 26.2261 12.924 26.1565 12.9266H25.0285C24.961 12.923 24.8977 12.8919 24.852 12.8402C24.8064 12.7885 24.7821 12.7203 24.7845 12.6502V9.04592C24.7845 8.43635 24.5643 8.2751 24.2981 8.2751C23.9654 8.2751 23.7009 8.54976 23.4568 9.02111V12.6502C23.4592 12.7203 23.4349 12.7885 23.3893 12.8402C23.3436 12.8919 23.2803 12.923 23.2128 12.9266H22.0848C22.0147 12.9244 21.9481 12.8946 21.8983 12.8433C21.8486 12.792 21.8195 12.723 21.8169 12.6502V7.48301C21.8194 7.41083 21.8481 7.34229 21.8971 7.29105C21.9461 7.23981 22.0119 7.20965 22.0814 7.20657H23.0336C23.0946 7.20593 23.1536 7.22936 23.1985 7.27213C23.2435 7.31489 23.2712 7.3738 23.276 7.43694L23.3647 7.89589C23.5413 7.6269 23.7792 7.40748 24.0573 7.25721C24.3354 7.10694 24.645 7.03049 24.9585 7.03469C25.2384 7.02021 25.5154 7.09814 25.75 7.25732C25.9845 7.4165 26.1644 7.64873 26.264 7.9207C26.7059 7.32353 27.2128 7.03469 27.9005 7.03469C28.7622 7.03469 29.3373 7.6673 29.3373 8.74645"
        fill="#414042"></path>
      <path
        d="M19.3683 12.9386C18.9485 14.3048 18.085 15.1324 16.5151 15.2936C16.4479 15.3 16.3809 15.2798 16.3275 15.2371C16.274 15.1944 16.238 15.1323 16.2267 15.0633L16.138 14.3863C16.127 14.3192 16.1417 14.2503 16.179 14.1944C16.2163 14.1384 16.2733 14.0998 16.3376 14.0869C17.0902 13.9487 17.4656 13.697 17.7318 13.2133C17.5612 13.169 17.3325 12.8695 17.2676 12.6498L15.7318 7.56234C15.7181 7.52202 15.7139 7.47886 15.7197 7.43652C15.7255 7.39418 15.741 7.35391 15.7649 7.31913C15.7889 7.28434 15.8206 7.25607 15.8573 7.23671C15.894 7.21736 15.9347 7.20748 15.9759 7.20793H17.1482C17.2091 7.2094 17.2679 7.23148 17.3157 7.27082C17.3634 7.31016 17.3975 7.36461 17.4127 7.42589L18.3513 11.84L19.3905 7.40994C19.4036 7.35235 19.4346 7.30083 19.4787 7.26336C19.5228 7.22589 19.5776 7.20454 19.6345 7.20262H20.7625C20.8036 7.20204 20.8443 7.21186 20.881 7.23122C20.9178 7.25058 20.9494 7.27891 20.9732 7.31377C20.9971 7.34863 21.0124 7.38898 21.0179 7.43135C21.0234 7.47373 21.0189 7.51685 21.0048 7.55702L19.3683 12.9386Z"
        fill="#414042"></path>
      <path
        d="M51.1683 9.964C51.1683 8.11048 50.2041 7.03487 48.6273 7.03487C46.9584 7.03487 46.0557 8.39046 46.0557 10.104C46.0557 11.8919 46.9891 13.1164 48.8253 13.1164C49.4598 13.1211 50.0822 12.9366 50.6188 12.5848L50.6734 12.5494C50.698 12.5347 50.722 12.5187 50.7451 12.5015C50.7988 12.4609 50.8357 12.4007 50.8483 12.3329C50.861 12.2652 50.8485 12.195 50.8133 12.1365L50.4618 11.5854C50.4248 11.5303 50.3697 11.4912 50.3069 11.4752C50.2441 11.4592 50.1778 11.4675 50.1205 11.4986C50.0539 11.5411 49.9857 11.5801 49.9157 11.6208C49.6276 11.7917 49.3021 11.8832 48.9703 11.8866C48.2963 11.8866 47.8287 11.6067 47.7144 10.5824H51.1273C51.1516 10.3772 51.1653 10.1708 51.1683 9.964ZM49.5608 9.55467H47.7297C47.8031 8.53044 48.1239 8.16541 48.6751 8.16541C49.3287 8.16541 49.5556 8.69701 49.5659 9.47847L49.5608 9.55467Z"
        fill="#414042"></path>
      <path
        d="M35.329 12.1828L35.1072 12.8721C35.086 12.9311 35.046 12.9809 34.9939 13.0131C34.9417 13.0453 34.8806 13.0581 34.8205 13.0493C34.2898 12.9695 33.9348 12.7516 33.7147 12.2448C33.3171 12.8756 32.7181 13.106 32.0543 13.106C30.9707 13.106 30.2625 12.36 30.2625 11.3145C30.2625 10.0741 31.1704 9.39716 32.8512 9.39716H33.4041V9.15085C33.4041 8.47394 33.1618 8.26485 32.4758 8.26485C32.0691 8.28206 31.6668 8.35964 31.2813 8.49521C31.2134 8.51555 31.1406 8.50862 31.0773 8.47579C31.0141 8.44297 30.9651 8.38664 30.94 8.31801L30.7403 7.6978C30.719 7.63075 30.7233 7.55777 30.7521 7.49385C30.781 7.42993 30.8324 7.3799 30.8956 7.35403C31.4914 7.15439 32.1126 7.04808 32.7386 7.03861C34.3324 7.03861 35.0184 7.72615 35.0184 9.05871V11.2773C35.0184 11.6086 35.085 11.7805 35.2164 11.8851C35.2632 11.9157 35.2993 11.9612 35.3193 12.0149C35.3393 12.0685 35.3421 12.1274 35.3273 12.1828H35.329ZM33.4041 11.3446V10.3346H33.0065C32.254 10.3346 31.9007 10.6092 31.9007 11.1727C31.9007 11.6317 32.143 11.8957 32.5424 11.8957C32.7207 11.8938 32.8951 11.8415 33.0469 11.7444C33.1988 11.6473 33.3223 11.5091 33.4041 11.3446Z"
        fill="#414042"></path>
      <path
        d="M39.694 7.06458H39.6428C39.5652 7.05571 39.4871 7.05157 39.409 7.05218C38.7759 7.05218 38.2384 7.5253 37.9893 8.32271L37.8954 7.6139V7.49518C37.889 7.41981 37.8557 7.34966 37.802 7.2985C37.7483 7.24735 37.6782 7.2189 37.6053 7.21875H36.7026C36.6674 7.21803 36.6324 7.22468 36.5997 7.23829C36.5669 7.25191 36.5372 7.27222 36.5122 7.29801C36.4872 7.32379 36.4675 7.35454 36.4542 7.38841C36.4408 7.42229 36.4342 7.45859 36.4347 7.49518V12.65C36.4342 12.6863 36.4408 12.7224 36.454 12.7561C36.4673 12.7897 36.4869 12.8203 36.5117 12.8459C36.5366 12.8715 36.5661 12.8917 36.5986 12.9052C36.6311 12.9187 36.6659 12.9253 36.7009 12.9246H37.8084C37.8434 12.9253 37.8782 12.9187 37.9107 12.9052C37.9432 12.8917 37.9727 12.8715 37.9975 12.8459C38.0224 12.8203 38.042 12.7897 38.0552 12.7561C38.0685 12.7224 38.0751 12.6863 38.0746 12.65V10.0522C38.2606 9.20163 38.5422 8.69483 39.2145 8.69483C39.2871 8.69517 39.3595 8.70109 39.4312 8.71255H39.4893C39.5517 8.70863 39.611 8.68283 39.6575 8.63938C39.704 8.59593 39.7349 8.53739 39.7452 8.47333L39.9244 7.36405C39.9305 7.32834 39.9292 7.29169 39.9206 7.25654C39.912 7.22139 39.8962 7.18855 39.8744 7.16021C39.8526 7.13187 39.8253 7.10868 39.7942 7.09219C39.7631 7.0757 39.7289 7.06629 39.694 7.06458Z"
        fill="#414042"></path>
      <path
        d="M42.2944 5.07233C42.2947 5.03463 42.2872 4.9973 42.2725 4.96286C42.2577 4.92841 42.236 4.89765 42.2089 4.87264C42.1817 4.84762 42.1497 4.82894 42.115 4.81784C42.0803 4.80674 42.0438 4.80349 42.0077 4.8083L40.902 4.93411C40.8367 4.94351 40.7766 4.97625 40.732 5.02671C40.6875 5.07717 40.6613 5.14218 40.6579 5.21054V12.6654C40.6572 12.7024 40.6638 12.7391 40.6772 12.7733C40.6906 12.8075 40.7107 12.8386 40.7361 12.8644C40.7615 12.8903 40.7918 12.9106 40.825 12.9239C40.8582 12.9372 40.8937 12.9433 40.9293 12.9418H42.035C42.0696 12.9416 42.1038 12.9342 42.1356 12.9202C42.1674 12.9061 42.1963 12.8856 42.2204 12.8599C42.2445 12.8342 42.2635 12.8038 42.2762 12.7704C42.2889 12.737 42.2951 12.7013 42.2944 12.6654V5.07233Z"
        fill="#414042"></path>
      <path
        d="M45.6715 12.6643L45.6459 12.6146L44.0315 9.72804L45.5366 7.4829L45.5588 7.44391C45.6373 7.32873 45.5998 7.23127 45.4547 7.23127H44.2602C44.1845 7.23474 44.1105 7.25561 44.0435 7.29238C43.9765 7.32916 43.9182 7.38091 43.8728 7.44391L42.3609 9.83436L43.849 12.6696C43.8928 12.7464 43.9535 12.8113 44.0262 12.8591C44.0989 12.9068 44.1814 12.936 44.267 12.9442H45.5605C45.7073 12.9389 45.7534 12.8131 45.6715 12.6643Z"
        fill="#414042"></path>
      <path
        d="M69.0672 0.499268H9.03728C6.67871 0.499737 4.41691 1.47299 2.74931 3.20497C1.08172 4.93696 0.144897 7.28583 0.144897 9.73499V10.2666C0.14535 12.7154 1.08237 15.0639 2.74991 16.7955C4.41746 18.5271 6.67901 19.5001 9.03728 19.5005H69.0672C70.2352 19.5003 71.3917 19.2612 72.4707 18.7968C73.5497 18.3325 74.5301 17.652 75.3558 16.7942C76.1815 15.9364 76.8365 14.9182 77.2832 13.7975C77.73 12.6769 77.9598 11.4759 77.9596 10.263V9.73144C77.9587 7.2829 77.0215 4.93494 75.354 3.20372C73.6865 1.4725 71.4252 0.499737 69.0672 0.499268ZM60.4393 10.2046C60.4366 12.1036 59.7081 13.9238 58.4139 15.2655C57.1196 16.6071 55.3655 17.3604 53.5367 17.3599H9.4144C7.58564 17.3604 5.83147 16.6071 4.53722 15.2655C3.24297 13.9238 2.51447 12.1036 2.51176 10.2046V9.79169C2.51447 7.89268 3.24297 6.07244 4.53722 4.7308C5.83147 3.38916 7.58564 2.63584 9.4144 2.63631H53.5367C55.3655 2.63584 57.1196 3.38916 58.4139 4.7308C59.7081 6.07244 60.4366 7.89268 60.4393 9.79169V10.2046Z"
        fill="#FFD102"></path>
      <path
        d="M63.8899 11.6166C63.8859 11.8526 63.7939 12.0779 63.6331 12.2448C63.4723 12.4117 63.2554 12.5073 63.0281 12.5114C62.7996 12.5114 62.5804 12.4171 62.4188 12.2493C62.2571 12.0815 62.1663 11.8539 62.1663 11.6166C62.1663 11.3792 62.2571 11.1516 62.4188 10.9838C62.5804 10.816 62.7996 10.7217 63.0281 10.7217C63.2553 10.7262 63.472 10.822 63.6326 10.9888C63.7933 11.1557 63.8855 11.3807 63.8899 11.6166Z"
        fill="white"></path>
      <path
        d="M68.9342 7.50602C68.5419 7.59397 68.1409 7.63264 67.7397 7.6212C68.3813 7.90649 68.6902 8.29811 68.6902 8.97679C68.6902 9.98683 67.8933 10.7222 66.5878 10.7222C66.3946 10.7275 66.2015 10.7085 66.0127 10.6655C65.9615 10.695 65.9186 10.7381 65.8887 10.7904C65.8587 10.8427 65.8426 10.9023 65.8421 10.9632C65.8421 11.1245 65.9087 11.2733 66.395 11.2733H67.168C68.3421 11.2733 69.0946 11.9166 69.0946 12.7884C69.0946 13.8693 68.1646 14.5232 66.4394 14.5232C64.7141 14.5232 64.1612 14.0075 64.0503 13.1446C64.0467 13.1071 64.0508 13.0692 64.0621 13.0335C64.0734 12.9977 64.0917 12.9647 64.116 12.9367C64.1402 12.9087 64.1698 12.8863 64.2029 12.8708C64.2359 12.8553 64.2717 12.8472 64.308 12.8469H65.1476C65.2051 12.8466 65.261 12.8671 65.3055 12.9049C65.35 12.9428 65.3805 12.9955 65.3916 13.0542C65.4581 13.3643 65.7226 13.4901 66.4752 13.4901C67.3609 13.4901 67.5588 13.2367 67.5588 12.9036C67.5588 12.5704 67.3608 12.3879 66.8506 12.3879H66.1202C65.0145 12.3879 64.5725 11.9166 64.5725 11.3531C64.5849 11.1485 64.646 10.9503 64.7502 10.7762C64.8544 10.6021 64.9986 10.4575 65.1697 10.3554C64.5281 10.001 64.2636 9.53851 64.2636 8.86161C64.2636 7.6779 65.1697 6.94429 66.482 6.94429C67.2129 6.98265 67.939 6.80245 68.5742 6.42509C68.6089 6.40816 68.6466 6.39892 68.685 6.39796C68.7233 6.39699 68.7614 6.40433 68.7969 6.41949C68.8324 6.43466 68.8644 6.45733 68.891 6.48605C68.9176 6.51478 68.9381 6.54893 68.9513 6.58634L69.1219 7.16048C69.1327 7.19668 69.136 7.23482 69.1316 7.27243C69.1273 7.31004 69.1154 7.3463 69.0968 7.37885C69.0781 7.41141 69.0531 7.43955 69.0234 7.46145C68.9936 7.48335 68.9597 7.49853 68.924 7.50602H68.9342ZM65.738 8.83857C65.738 9.4127 66.0469 9.72458 66.5127 9.72458C66.9786 9.72458 67.2636 9.41447 67.2636 8.81731C67.2636 8.22014 67.0213 7.96674 66.5127 7.96674C66.0469 7.96674 65.738 8.29988 65.738 8.84034"
        fill="white"></path>
      <path
        d="M70.8592 10.1822C70.9479 11.1249 71.3899 11.3765 72.0093 11.3765C72.3657 11.3698 72.7129 11.2586 73.011 11.0558C73.0669 11.0194 73.1335 11.0051 73.1987 11.0156C73.2638 11.026 73.3232 11.0604 73.366 11.1125L73.6749 11.5608C73.6968 11.5904 73.7126 11.6245 73.7212 11.6609C73.7298 11.6972 73.731 11.735 73.7247 11.7718C73.7184 11.8087 73.7049 11.8438 73.6848 11.8748C73.6648 11.9059 73.6387 11.9323 73.6083 11.9524C73.0934 12.3175 72.4844 12.5127 71.8609 12.5124C70.18 12.5124 69.3387 11.3871 69.3387 9.75688C69.3387 8.18333 70.1578 6.9447 71.6851 6.9447C73.122 6.9447 74.0059 7.92993 74.0059 9.62043C74.0101 9.80797 74.0026 9.9956 73.9837 10.1822H70.8592ZM72.54 9.18275C72.5179 8.47394 72.3182 7.97601 71.7209 7.97601C71.209 7.97601 70.9257 8.31092 70.8592 9.25185H72.54V9.18275Z"
        fill="white"></path>
    </svg>
  )
}
