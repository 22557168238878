import { IIcon } from "./interface"

export const YardAreaIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" className={className}>
      <g clipPath="url(#clip0_6771_10161)">
        <path d="M1.55 16C1.10817 16 0.750001 15.6418 0.750001 15.2V15.2C0.750001 14.7582 1.10817 14.4 1.55 14.4H5.25V11.2H3.75C2.7125 11.2 1.828 10.8099 1.0965 10.0296C0.365 9.24933 -0.000499488 8.30613 5.12295e-07 7.2C5.12295e-07 6.4 0.20625 5.6632 0.61875 4.9896C1.03125 4.316 1.5875 3.82613 2.2875 3.52C2.4 2.52 2.8095 1.6832 3.516 1.0096C4.2225 0.336001 5.0505 -0.0005327 6 6.32911e-07C6.95 6.32911e-07 7.77825 0.336801 8.48475 1.0104C9.19125 1.684 9.6005 2.52053 9.7125 3.52C10.4125 3.82667 10.9688 4.3168 11.3813 4.9904C11.7938 5.664 12 6.40053 12 7.2C12 8.30667 11.6343 9.25013 10.9028 10.0304C10.1713 10.8107 9.287 11.2005 8.25 11.2H6.75V14.4H10.45C10.8918 14.4 11.25 14.7582 11.25 15.2V15.2C11.25 15.6418 10.8918 16 10.45 16H1.55ZM3.75 9.6H8.25C8.875 9.6 9.40625 9.36667 9.84375 8.9C10.2813 8.43333 10.5 7.86667 10.5 7.2C10.5 6.72 10.3718 6.28 10.1153 5.88C9.85875 5.48 9.5245 5.18667 9.1125 5L8.325 4.64L8.2125 3.72C8.1375 3.12 7.89075 2.61653 7.47225 2.2096C7.05375 1.80267 6.563 1.59947 6 1.6C5.4375 1.6 4.94675 1.80347 4.52775 2.2104C4.10875 2.61733 3.862 3.12053 3.7875 3.72L3.675 4.64L2.8875 5C2.475 5.18667 2.14075 5.48 1.88475 5.88C1.62875 6.28 1.5005 6.72 1.5 7.2C1.5 7.86667 1.71875 8.43333 2.15625 8.9C2.59375 9.36667 3.125 9.6 3.75 9.6Z" />
      </g>
      <defs>
        <clipPath id="clip0_6771_10161">
          <rect width="12" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
