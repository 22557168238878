import { IIcon } from "./interface"

export const DefaultLocationsIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="12" height="17" viewBox="0 0 13 17.17" className={className}>
      <g>
        <g>
          <path d="M6.5,1A5.47,5.47,0,0,1,12,6.43c0,3.19-1.76,6-3.24,7.83-1,1.27-1.82,1.91-2.34,1.91S4.83,15,4.19,14.2C2,11.36,1,8.82,1,6.43A5.47,5.47,0,0,1,6.5,1m0,8A2.5,2.5,0,1,0,4,6.5,2.5,2.5,0,0,0,6.5,9m0-9A6.46,6.46,0,0,0,0,6.43c0,3.31,1.77,6.24,3.4,8.38,1.16,1.54,2.07,2.36,3,2.36s1.86-.73,3.12-2.28A13.92,13.92,0,0,0,13,6.43,6.47,6.47,0,0,0,6.5,0Zm0,8A1.5,1.5,0,1,1,8,6.5,1.5,1.5,0,0,1,6.5,8Z"></path>
        </g>
      </g>
    </svg>
  )
}
