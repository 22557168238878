import { IIcon } from "./interface"

export const MyJobsIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="62" height="20" viewBox="0 0 62 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M52.5001 0.475525H10.0921C4.97388 0.475525 0.830566 4.61655 0.830566 9.73194V10.2557C0.830566 15.371 4.97388 19.5121 10.0921 19.5121H52.5001C57.6183 19.5121 61.7616 15.371 61.7616 10.2557V9.73194C61.7616 4.61655 57.6183 0.475525 52.5001 0.475525Z"
        fill="#A155B9"
      />
      <path
        d="M44.0185 10.2069C44.0185 14.1653 40.8014 17.3685 36.8409 17.3685H10.4699C6.50939 17.3685 3.29224 14.1653 3.29224 10.2069V9.79283C3.29224 5.83449 6.50939 2.63129 10.4699 2.63129H36.8409C40.8014 2.63129 44.0185 5.83449 44.0185 9.79283V10.2069Z"
        fill="white"
      />
      <path
        d="M47.9059 11.4127C47.9059 11.8512 47.5281 12.2287 47.0894 12.2287C46.6264 12.2287 46.2729 11.8512 46.2729 11.4127C46.2729 10.9742 46.6264 10.5967 47.0894 10.5967C47.5281 10.5967 47.9059 10.9742 47.9059 11.4127Z"
        fill="white"
      />
      <path
        d="M52.6828 7.67359C52.3904 7.74667 52.037 7.78321 51.5495 7.78321C52.1588 8.03898 52.4513 8.40436 52.4513 9.01334C52.4513 9.93898 51.6958 10.6088 50.4649 10.6088C50.2578 10.6088 50.0872 10.5967 49.9166 10.5601C49.8069 10.6088 49.746 10.7306 49.746 10.8281C49.746 10.9742 49.8069 11.1082 50.27 11.1082H51.0011C52.1101 11.1082 52.8291 11.6928 52.8291 12.4967C52.8291 13.4832 51.9517 14.08 50.3187 14.08C48.7101 14.08 48.1618 13.605 48.0521 12.8255C48.0277 12.6794 48.1618 12.5576 48.308 12.5576H49.1001C49.2098 12.5576 49.3073 12.6306 49.3316 12.7403C49.3926 13.0204 49.6485 13.1422 50.3553 13.1422C51.1961 13.1422 51.3789 12.9108 51.3789 12.6063C51.3789 12.3262 51.1961 12.1313 50.7087 12.1313H50.0141C48.966 12.1313 48.5517 11.705 48.5517 11.1935C48.5517 10.8524 48.7833 10.4871 49.1123 10.28C48.503 9.95116 48.2471 9.53705 48.2471 8.9159C48.2471 7.83193 49.1123 7.16205 50.3431 7.16205C51.2449 7.18641 51.8298 6.97936 52.3294 6.68705C52.4513 6.61398 52.6219 6.68705 52.6828 6.83321L52.8534 7.35693C52.9144 7.4909 52.8291 7.63706 52.6828 7.67359ZM49.6728 8.87936C49.6728 9.40308 49.9653 9.68321 50.404 9.68321C50.8671 9.68321 51.123 9.40308 51.123 8.855C51.123 8.33129 50.8915 8.07552 50.404 8.07552C49.9653 8.0877 49.6728 8.39218 49.6728 8.87936Z"
        fill="white"
      />
      <path
        d="M57.4599 10.1095H54.5108C54.5961 10.9621 55.0105 11.1935 55.5954 11.1935C55.9122 11.1935 56.2047 11.0961 56.5337 10.9012C56.6434 10.8281 56.7896 10.8646 56.875 10.9499L57.1674 11.364C57.2527 11.4736 57.2283 11.632 57.1065 11.7172C56.6678 12.0095 56.1194 12.2287 55.4492 12.2287C53.8528 12.2287 53.0607 11.2057 53.0607 9.71977C53.0607 8.28259 53.8406 7.1499 55.2786 7.1499C56.6434 7.1499 57.4843 8.05118 57.4843 9.5858C57.4843 9.75631 57.4843 9.97554 57.4599 10.1095ZM56.1072 9.19606C56.0828 8.55054 55.9001 8.0999 55.3273 8.0999C54.8399 8.0999 54.5718 8.40439 54.5108 9.25695H56.1072V9.19606Z"
        fill="white"
      />
      <path
        d="M14.796 8.80632V12.3627C14.796 12.4967 14.6863 12.6185 14.5401 12.6185H13.4921C13.3458 12.6185 13.2362 12.5089 13.2362 12.3627V9.07427C13.2362 8.51401 13.0534 8.36786 12.7975 8.36786C12.4806 8.36786 12.2369 8.62363 12.0175 9.04991V12.3627C12.0175 12.4967 11.8957 12.6185 11.7616 12.6185H10.6893C10.5674 12.6185 10.4577 12.5089 10.4577 12.3627V9.07427C10.4577 8.51401 10.2505 8.36786 9.99464 8.36786C9.6778 8.36786 9.43407 8.62363 9.20253 9.04991V12.3627C9.20253 12.4967 9.09286 12.6185 8.971 12.6185H7.89861C7.77675 12.6185 7.6427 12.5089 7.6427 12.3627V7.64927C7.6427 7.5153 7.76456 7.3935 7.89861 7.3935H8.80039C8.92225 7.3935 9.00756 7.47876 9.03193 7.60055L9.11723 8.01466C9.51938 7.49094 9.99464 7.23517 10.6283 7.23517C11.2133 7.23517 11.652 7.52748 11.8591 8.05119C12.2735 7.50312 12.7609 7.23517 13.4068 7.23517C14.2476 7.23517 14.796 7.81978 14.796 8.80632Z"
        fill="#414042"
      />
      <path
        d="M18.854 12.6185C18.4519 13.8608 17.6354 14.6159 16.1487 14.7621C16.0268 14.7743 15.8928 14.689 15.8806 14.555L15.7953 13.9339C15.7709 13.8121 15.8562 13.6781 15.9781 13.6659C16.6849 13.5441 17.0505 13.3127 17.2942 12.8743C17.1236 12.8377 16.9164 12.5576 16.8555 12.3627L15.4053 7.72234C15.3444 7.56401 15.4663 7.39349 15.6369 7.39349H16.7458C16.8677 7.39349 16.953 7.47875 17.0017 7.58837L17.8791 11.6076L18.8662 7.57619C18.8906 7.46657 18.9881 7.39349 19.0977 7.39349H20.1701C20.3407 7.39349 20.4626 7.56401 20.4017 7.72234L18.854 12.6185Z"
        fill="#414042"
      />
      <path
        d="M38.1204 9.39091C37.182 9.11078 37.048 9.00117 37.048 8.72104C37.048 8.48963 37.2551 8.3313 37.6817 8.3313C38.0229 8.3313 38.3519 8.41655 38.6687 8.57488C38.7053 8.58706 38.7297 8.61142 38.7662 8.6236C38.8759 8.6845 39.0343 8.64796 39.1074 8.53835L39.4121 8.03899C39.4852 7.92937 39.4487 7.77104 39.339 7.71014C39.3024 7.68578 39.2171 7.63706 39.2171 7.63706C38.7784 7.38129 38.2178 7.22296 37.6207 7.22296C36.3046 7.22296 35.4881 7.91719 35.4881 8.81847C35.4881 9.62232 36 10.1582 37.0236 10.4505C37.9619 10.7185 38.1204 10.8281 38.1204 11.1813C38.1204 11.498 37.8401 11.6685 37.3648 11.6685C36.9627 11.6685 36.5849 11.5467 36.2437 11.3396C36.2071 11.3031 36.1584 11.2787 36.1218 11.2422C36.0243 11.1691 35.8781 11.2178 35.805 11.3274L35.4272 11.8512C35.3541 11.9608 35.3785 12.1069 35.4638 12.1922C35.5003 12.2166 35.5247 12.2409 35.5613 12.2653C36.0487 12.5819 36.6824 12.789 37.4136 12.789C38.7053 12.789 39.7289 12.1557 39.7289 11.0717C39.7289 10.1217 39.1562 9.68322 38.1204 9.39091Z"
        fill="#414042"
      />
      <path
        d="M34.7936 9.96339C34.7936 11.5954 34.0137 12.7647 32.6975 12.7647C32.1126 12.7647 31.6373 12.5089 31.3205 12.0948L31.2839 12.3506C31.2596 12.4967 31.1377 12.5942 30.9915 12.5942H30.1506C29.9922 12.5942 29.8582 12.4602 29.8582 12.3019V5.49352C29.8582 5.34736 29.9678 5.21339 30.1263 5.20121L31.1133 5.09159C31.2839 5.06724 31.4424 5.21339 31.4424 5.3839V7.85634C31.7592 7.41788 32.271 7.14993 32.8681 7.14993C34.099 7.16211 34.7936 8.19736 34.7936 9.96339ZM33.1728 9.96339C33.1728 8.62365 32.8194 8.30698 32.3198 8.30698C31.9664 8.30698 31.6495 8.53839 31.4302 8.92813V11.1204C31.6252 11.4371 31.9176 11.632 32.2588 11.632C32.795 11.632 33.1728 11.2057 33.1728 9.96339Z"
        fill="#414042"
      />
      <path
        d="M23.0705 12.0461C23.0705 13.6538 22.3027 14.2993 21.1938 14.7378C21.0598 14.7865 20.9013 14.7378 20.8404 14.6038L20.5357 13.9339C20.4748 13.7999 20.5357 13.6416 20.6576 13.5807C21.1938 13.3128 21.4985 12.9839 21.4985 12.1435V7.67365C21.4985 7.5275 21.6203 7.4057 21.7666 7.4057H22.7902C22.9364 7.4057 23.0583 7.5275 23.0583 7.67365V12.0461H23.0705Z"
        fill="#414042"
      />
      <path
        d="M29.0661 9.99988C29.0661 11.6685 28.1034 12.7646 26.507 12.7646C24.935 12.7646 23.9479 11.7416 23.9479 9.98771C23.9479 8.31912 24.9106 7.22296 26.507 7.22296C28.0912 7.23514 29.0661 8.24604 29.0661 9.99988ZM25.5565 9.99988C25.5565 11.1569 25.8733 11.6319 26.4948 11.6319C27.1285 11.6319 27.4331 11.1326 27.4331 10.0121C27.4331 8.85501 27.1163 8.36783 26.4948 8.36783C25.8733 8.35565 25.5565 8.86719 25.5565 9.99988Z"
        fill="#414042"
      />
      <path
        d="M23.2167 5.6153C23.2167 6.12684 22.8267 6.5044 22.2783 6.5044C21.7299 6.5044 21.3522 6.11466 21.3522 5.6153C21.3522 5.10376 21.7299 4.7262 22.2783 4.7262C22.8267 4.7262 23.2167 5.11594 23.2167 5.6153Z"
        fill="#414042"
      />
    </svg>
  )
}
