import { IIcon } from "./interface"

export const LogoIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="138" height="40" viewBox="0 0 138 40" fill="none" className={className}>
      <g clipPath="url(#clip0_5835_18081)">
        <path
          d="M118.86 0.777649H19.3198C8.81981 0.777649 0.299805 9.22813 0.299805 19.6425V20.7137C0.299805 31.128 8.81981 39.5785 19.3198 39.5785H118.8C129.3 39.5785 137.82 31.128 137.82 20.7137V19.6425C137.88 9.22813 129.36 0.777649 118.86 0.777649Z"
          fill="#26B571"
        />
        <path
          d="M101.46 20.5937C101.46 28.6276 94.8599 35.1738 86.6999 35.1738H20.0998C11.9998 35.1738 5.33984 28.6276 5.33984 20.5937V19.7606C5.33984 11.7267 11.9398 5.18048 20.0998 5.18048H86.6399C94.7399 5.18048 101.4 11.7267 101.4 19.7606V20.5937H101.46Z"
          fill="white"
        />
        <path
          d="M109.44 23.0355C109.44 23.9282 108.66 24.7018 107.76 24.7018C106.8 24.7018 106.08 23.9282 106.08 23.0355C106.08 22.1428 106.8 21.3692 107.76 21.3692C108.66 21.3692 109.44 22.1428 109.44 23.0355Z"
          fill="white"
        />
        <path
          d="M119.22 15.4181C118.62 15.5372 117.9 15.6562 116.88 15.6562C118.14 16.1918 118.74 16.906 118.74 18.1557C118.74 20.06 117.18 21.3692 114.66 21.3692C114.24 21.3692 113.88 21.3692 113.52 21.2502C113.28 21.3692 113.16 21.6072 113.16 21.7858C113.16 22.0833 113.28 22.3809 114.24 22.3809H115.74C118.02 22.3809 119.46 23.5711 119.46 25.1779C119.46 27.2012 117.66 28.3914 114.3 28.3914C111 28.3914 109.86 27.4393 109.68 25.8325C109.62 25.5349 109.92 25.297 110.22 25.297H111.84C112.08 25.297 112.26 25.4159 112.32 25.654C112.44 26.2491 112.98 26.4872 114.42 26.4872C116.16 26.4872 116.52 26.011 116.52 25.4159C116.52 24.8208 116.16 24.4637 115.14 24.4637H113.7C111.54 24.4637 110.7 23.5711 110.7 22.5594C110.7 21.8453 111.18 21.1312 111.84 20.7146C110.58 20.06 110.1 19.2268 110.1 17.9176C110.1 15.7157 111.84 14.347 114.42 14.347C116.28 14.4065 117.48 13.99 118.5 13.3949C118.74 13.2758 119.1 13.3949 119.22 13.6924L119.58 14.7635C119.7 15.0611 119.52 15.3586 119.22 15.4181ZM113.04 17.9176C113.04 18.9888 113.64 19.5839 114.54 19.5839C115.5 19.5839 115.98 18.9888 115.98 17.9176C115.98 16.8464 115.5 16.3108 114.54 16.3108C113.64 16.2513 113.04 16.9059 113.04 17.9176Z"
          fill="white"
        />
        <path
          d="M129.06 20.4175H123C123.18 22.1433 124.02 22.6194 125.22 22.6194C125.88 22.6194 126.48 22.4408 127.14 22.0243C127.38 21.8457 127.68 21.9648 127.8 22.1433L128.4 22.9764C128.58 23.2145 128.52 23.512 128.28 23.6906C127.38 24.2857 126.24 24.7617 124.86 24.7617C121.62 24.7617 119.94 22.6789 119.94 19.6438C119.94 16.7278 121.56 14.4069 124.5 14.4069C127.32 14.4069 129 16.2517 129 19.4058C129.06 19.6438 129.06 20.1199 129.06 20.4175ZM126.24 18.5131C126.18 17.2039 125.82 16.2517 124.62 16.2517C123.66 16.2517 123.06 16.8469 122.94 18.6322H126.18V18.5131H126.24Z"
          fill="white"
        />
        <path
          d="M29.0402 17.6777V24.938C29.0402 25.2355 28.8002 25.4736 28.5002 25.4736H26.3402C26.0402 25.4736 25.8002 25.2355 25.8002 24.938V18.2133C25.8002 17.0826 25.4402 16.785 24.9002 16.785C24.2402 16.785 23.7602 17.3206 23.2802 18.1538V24.8784C23.2802 25.176 23.0402 25.414 22.7402 25.414H20.5202C20.2802 25.414 20.0402 25.176 20.0402 24.8784V18.2133C20.0402 17.0826 19.6202 16.785 19.0802 16.785C18.4202 16.785 17.9402 17.3206 17.4602 18.1538V24.8784C17.4602 25.176 17.2202 25.414 16.9802 25.414H14.7602C14.5202 25.414 14.2202 25.176 14.2202 24.8784V15.2973C14.2202 14.9997 14.4602 14.7617 14.7602 14.7617H16.6202C16.8602 14.7617 17.0402 14.9402 17.1002 15.1782L17.2802 16.0114C18.1202 14.9402 19.0802 14.4046 20.4002 14.4046C21.6002 14.4046 22.5002 14.9997 22.9202 16.0709C23.7602 14.9402 24.7802 14.4046 26.1002 14.4046C27.9002 14.4641 29.0402 15.6543 29.0402 17.6777Z"
          fill="#414042"
        />
        <path
          d="M80.5202 17.6777V24.938C80.5202 25.2355 80.2802 25.4736 79.9802 25.4736H77.8202C77.5202 25.4736 77.2802 25.2355 77.2802 24.938V18.2133C77.2802 17.0826 76.9202 16.785 76.3802 16.785C75.7202 16.785 75.2402 17.3206 74.7602 18.1538V24.8784C74.7602 25.176 74.5202 25.414 74.2202 25.414H72.0002C71.7602 25.414 71.5202 25.176 71.5202 24.8784V18.2133C71.5202 17.0826 71.1002 16.785 70.5602 16.785C69.9002 16.785 69.4202 17.3206 68.9402 18.1538V24.8784C68.9402 25.176 68.7002 25.414 68.4602 25.414H66.2402C66.0002 25.414 65.7002 25.176 65.7002 24.8784V15.2973C65.7002 14.9997 65.9402 14.7617 66.2402 14.7617H68.1002C68.3402 14.7617 68.5202 14.9402 68.5802 15.1782L68.7602 16.0114C69.6002 14.9402 70.5602 14.4046 71.8802 14.4046C73.0802 14.4046 73.9802 14.9997 74.4002 16.0709C75.2402 14.9402 76.2602 14.4046 77.5802 14.4046C79.3802 14.4641 80.5202 15.6543 80.5202 17.6777Z"
          fill="#414042"
        />
        <path
          d="M37.3798 25.4754C36.5398 28.0344 34.8598 29.5222 31.8598 29.8197C31.6198 29.8197 31.3198 29.6412 31.3198 29.4031L31.1398 28.1534C31.0798 27.9154 31.2598 27.6178 31.4998 27.6178C32.9398 27.3798 33.7198 26.9037 34.1998 26.011C33.8398 25.9515 33.4198 25.3564 33.2998 24.9398L30.3598 15.4777C30.2398 15.1801 30.4798 14.8231 30.8398 14.8231H33.1198C33.3598 14.8231 33.5398 15.0016 33.6598 15.2396L35.4598 23.4521L37.4998 15.2396C37.5598 15.0016 37.7398 14.8826 37.9798 14.8826H40.1998C40.5598 14.8826 40.7998 15.2396 40.6798 15.5372L37.3798 25.4754Z"
          fill="#414042"
        />
        <path
          d="M63.7799 20.1185C63.7799 23.5106 61.7999 25.772 58.5598 25.772C55.3198 25.772 53.3398 23.6892 53.3398 20.1185C53.3398 16.7264 55.3198 14.465 58.5598 14.465C61.7999 14.465 63.7799 16.5479 63.7799 20.1185ZM56.5798 20.1185C56.5798 22.4394 57.2398 23.4511 58.4998 23.4511C59.7598 23.4511 60.4199 22.4394 60.4199 20.1185C60.4199 17.7381 59.7598 16.7859 58.4998 16.7859C57.2398 16.7859 56.5798 17.7976 56.5798 20.1185Z"
          fill="#414042"
        />
        <path
          d="M92.4 19.941C92.4 16.4894 90.54 14.5255 87.48 14.5255C84.24 14.5255 82.5 17.025 82.5 20.2385C82.5 23.5711 84.3 25.8325 87.9 25.8325C89.28 25.8325 90.42 25.4159 91.38 24.8208C91.44 24.8208 91.44 24.7613 91.5 24.7613C91.56 24.7613 91.62 24.7018 91.62 24.6423C91.86 24.4638 91.92 24.1662 91.74 23.9877L91.08 22.976C90.84 22.6189 90.48 22.7975 90.42 22.7975C90.3 22.857 90.18 22.976 90 23.0355C89.4 23.3926 88.8 23.5116 88.14 23.5116C86.82 23.5116 85.92 22.976 85.68 21.0717H92.34C92.34 20.7741 92.4 20.298 92.4 19.941ZM89.28 19.1673H85.74C85.86 17.263 86.52 16.6084 87.6 16.6084C88.86 16.6084 89.34 17.6201 89.34 19.0483V19.1673H89.28Z"
          fill="#414042"
        />
        <path
          d="M51.3597 17.6785C51.3597 15.6551 50.2197 14.4649 48.4197 14.4649C47.1597 14.4649 46.1997 14.941 45.3597 15.9527V12.3225V10.7752C45.3597 10.4777 45.1197 10.2396 44.8197 10.2992L42.6597 10.5372C42.4197 10.5967 42.1797 10.7752 42.1797 11.0728V11.1323V24.8792V24.9983C42.1797 25.2958 42.4197 25.5338 42.7197 25.5338H44.8797C45.1797 25.5338 45.4197 25.2958 45.4197 24.9983V18.2141C45.9597 17.3809 46.5597 16.8453 47.2797 16.8453C47.8797 16.8453 48.2997 17.1429 48.2997 18.2736V24.8792V24.9983C48.2997 25.2958 48.5397 25.5338 48.8397 25.5338H50.9997C51.2997 25.5338 51.5397 25.2958 51.5397 24.9983L51.3597 17.6785Z"
          fill="#414042"
        />
      </g>
      <defs>
        <clipPath id="clip0_5835_18081">
          <rect width="138" height="39.2769" fill="white" transform="translate(0 0.361511)" />
        </clipPath>
      </defs>
    </svg>
  )
}
