import { IIcon } from "./interface"

export const AddGlobalV2Icon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <rect y="0.5" width="20" height="20" rx="10" fill="#26B571" />
      <path
        d="M14.2857 11.2143H10.7143V14.7857C10.7143 15.1786 10.3929 15.5 10 15.5C9.60714 15.5 9.28571 15.1786 9.28571 14.7857V11.2143H5.71429C5.32143 11.2143 5 10.8929 5 10.5C5 10.1071 5.32143 9.78571 5.71429 9.78571H9.28571V6.21429C9.28571 5.82143 9.60714 5.5 10 5.5C10.3929 5.5 10.7143 5.82143 10.7143 6.21429V9.78571H14.2857C14.6786 9.78571 15 10.1071 15 10.5C15 10.8929 14.6786 11.2143 14.2857 11.2143Z"
        fill="white"
      />
    </svg>
  )
}
