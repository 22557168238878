import { IIcon } from "./interface"

export const SwoopIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="85" height="18" viewBox="0 0 85 18" fill="none" className={className}>
      <path
        d="M22.5699 4.6451L18.2328 1.0571C17.5428 0.475262 16.4585 0.184343 15.5714 0.281316L2.95441 2.51169C1.57442 2.70564 0.588721 4.06326 0.785861 5.42088L2.26441 14.0515C2.46155 15.4091 3.84154 16.3788 5.22152 16.1849L17.8385 14.0515C18.8242 13.8575 19.6128 13.2757 20.1056 12.4999L22.9642 7.65126C23.7527 6.58456 23.457 5.42088 22.5699 4.6451ZM10.7414 13.2757L8.86862 11.4332L12.22 8.03915L8.77005 4.74207L10.6429 2.89959L16.0642 8.03915L10.7414 13.2757Z"
        fill="#72BF44"></path>
      <path
        d="M33.9056 7.16627L32.9199 6.77838C31.9342 6.39049 30.8499 5.90563 30.8499 4.9359C30.8499 4.16011 31.4413 3.4813 32.8213 3.4813C34.2998 3.4813 35.5813 4.548 35.877 4.83892L36.1727 5.12984L36.567 3.67525L36.3698 3.57827C35.6798 3.19038 34.2998 2.80249 32.9199 2.80249C30.6527 2.80249 29.1742 4.06314 29.1742 5.80865C29.1742 7.74811 30.9485 8.42692 32.2299 9.00876L33.117 9.39665C34.497 9.97849 35.3841 10.3664 35.3841 11.3361C35.3841 12.3058 34.497 12.9847 33.117 12.9847C31.8356 12.9847 30.6527 12.4028 29.7656 11.4331L29.4699 11.1422L28.9771 12.6937L29.0756 12.7907C29.9628 13.3725 31.4413 13.7604 32.8213 13.7604C35.2855 13.7604 36.9612 12.4998 36.9612 10.5603C36.9612 8.42692 35.187 7.74811 33.9056 7.16627Z"
        fill="#231F20"></path>
      <path
        d="M51.6482 2.99609H49.9725V3.19004C49.8739 4.54766 49.2825 6.39015 48.3954 8.7175L47.9025 10.0751L45.1426 2.99609H44.7483L41.9883 10.1721L39.1298 2.99609H36.6655L41.0026 13.7601H41.4955L44.1569 6.77804L46.8183 13.7601H47.3111L49.1839 8.91145C50.1696 6.39015 50.7611 4.93555 51.5496 3.38399L51.6482 2.99609Z"
        fill="#231F20"></path>
      <path
        d="M56.6754 2.80273C53.4226 2.80273 51.254 5.03311 51.254 8.33019C51.254 11.5303 53.4226 13.7607 56.5768 13.7607C59.8296 13.7607 61.9982 11.5303 61.9982 8.23322C61.8996 4.93614 59.8296 2.80273 56.6754 2.80273ZM56.5768 3.48155C58.3511 3.48155 59.5339 5.421 59.5339 8.23322C59.5339 11.0454 58.3511 12.8879 56.5768 12.8879C54.8026 12.8879 53.6197 10.9485 53.6197 8.13625C53.6197 5.32403 54.8026 3.48155 56.5768 3.48155Z"
        fill="#231F20"></path>
      <path
        d="M68.1096 2.80273C64.8568 2.80273 62.6882 5.03311 62.6882 8.33019C62.6882 11.5303 64.8568 13.7607 68.011 13.7607C71.2638 13.7607 73.4324 11.5303 73.4324 8.23322C73.3338 4.93614 71.2638 2.80273 68.1096 2.80273ZM68.011 3.48155C69.7853 3.48155 70.9681 5.421 70.9681 8.23322C70.9681 11.0454 69.7853 12.8879 68.011 12.8879C66.2368 12.8879 65.0539 10.9485 65.0539 8.13625C65.0539 5.32403 66.2368 3.48155 68.011 3.48155Z"
        fill="#231F20"></path>
      <path
        d="M80.0365 2.80273C78.8536 2.80273 77.8679 3.19063 76.8822 4.06338V2.80273H76.6851L74.6151 3.38457V17.7366H76.8822V12.8879C77.6708 13.3728 78.4594 13.6637 79.4451 13.6637C82.4022 13.6637 84.5707 11.2394 84.5707 8.03928C84.5707 5.03311 82.6979 2.80273 80.0365 2.80273ZM76.8822 11.2394V5.13009C77.6708 4.3543 78.5579 3.86944 79.4451 3.86944C81.1207 3.86944 82.205 5.51798 82.205 8.23322C82.205 11.0454 81.0222 12.8879 79.2479 12.8879C78.2622 12.8879 77.2765 12.3061 76.8822 11.2394Z"
        fill="#231F20"></path>
    </svg>
  )
}
