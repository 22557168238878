import { IIcon } from "./interface"

export const CorporateFareIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="35" height="40" viewBox="0 0 35 40" fill="#26B571" className={className}>
      <path
        d="M17.4997 11.6667V8.33333C17.4997 6.5 15.9997 5 14.1663 5H4.16634C2.33301 5 0.833008 6.5 0.833008 8.33333V31.6667C0.833008 33.5 2.33301 35 4.16634 35H30.833C32.6663 35 34.1663 33.5 34.1663 31.6667V15C34.1663 13.1667 32.6663 11.6667 30.833 11.6667H17.4997ZM14.1663 31.6667H4.16634V28.3333H14.1663V31.6667ZM14.1663 25H4.16634V21.6667H14.1663V25ZM14.1663 18.3333H4.16634V15H14.1663V18.3333ZM14.1663 11.6667H4.16634V8.33333H14.1663V11.6667ZM30.833 31.6667H17.4997V15H30.833V31.6667ZM27.4997 18.3333H20.833V21.6667H27.4997V18.3333ZM27.4997 25H20.833V28.3333H27.4997V25Z"
        fill="#26B571"
      />
    </svg>
  )
}
