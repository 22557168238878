import { IIcon } from "./interface"

export const BoltPlusIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="37" height="35" viewBox="0 0 37 35" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40828 33.2533C6.31355 33.9144 6.82506 34.5 7.48813 34.5C7.88597 34.5 8.24593 34.2922 8.43537 33.9522C8.72204 33.4533 9.20372 32.6128 9.8812 31.4305C11.6662 28.3154 14.8106 22.8282 19.3286 14.9689C19.7454 14.0811 19.3286 13.7222 18.5709 13.7222H11.9212L13.6263 1.74667C13.721 1.08556 13.2095 0.5 12.5464 0.5C12.1675 0.5 11.7886 0.707778 11.5992 1.04778C9.43748 4.84109 7.51654 8.20158 5.83994 11.1346C3.84313 14.6278 2.1929 17.5147 0.895335 19.8044C0.894171 19.8062 0.892058 19.8092 0.889106 19.8134C0.79597 19.9468 -0.133293 21.2778 1.48262 21.2778H8.11331L6.40828 33.2533ZM29.9092 9.94442C28.6885 9.94442 27.699 10.9311 27.699 12.1481V14.3518H25.4888C24.2681 14.3518 23.2785 15.3385 23.2785 16.5555C23.2785 17.7726 24.2681 18.7592 25.4888 18.7592H27.699V20.9629C27.699 22.18 28.6885 23.1666 29.9092 23.1666C31.1299 23.1666 32.1194 22.18 32.1194 20.9629V18.7592H34.3297C35.5503 18.7592 36.5399 17.7726 36.5399 16.5555C36.5399 15.3385 35.5503 14.3518 34.3297 14.3518H32.1194V12.1481C32.1194 10.931 31.1299 9.94442 29.9092 9.94442Z"
        fill="#FCC000"
      />
    </svg>
  )
}
