import { IIcon } from "./interface"

export const NoResultIcon = ({ className }: IIcon) => {
  return (
    <svg width="127" height="116" viewBox="0 0 127 116" fill="none" className={className}>
      <g id="Frame 2">
        <g id="background">
          <circle id="Ellipse 6" cx="62.2501" cy="63.25" r="52.65" fill="#E9F8F1" />
          <circle id="Ellipse 5" cx="12.9001" cy="27.7" r="3.3" fill="#E9F8F1" />
          <circle id="Ellipse 4" cx="99.7501" cy="6.25001" r="6.15" fill="#E9F8F1" />
          <circle id="Ellipse 3" cx="13.9501" cy="97.15" r="5.85" fill="#E9F8F1" />
          <circle id="Ellipse 2" cx="118.65" cy="91.45" r="5.85" fill="#E9F8F1" />
          <circle id="Ellipse 1" cx="107.25" cy="98.05" r="2.85" fill="#E9F8F1" />
          <path
            id="Star 4"
            d="M121.398 73.3992L122.316 77.7394L126.594 78.6714L122.316 79.6034L121.398 83.9436L120.479 79.6034L116.201 78.6714L120.479 77.7394L121.398 73.3992Z"
            fill="white"
          />
          <path
            id="Star 3"
            d="M115.903 29.0866C115.971 28.7695 116.423 28.7695 116.49 29.0866L117.075 31.8505C117.1 31.966 117.19 32.0564 117.305 32.0815L120.048 32.6791C120.363 32.7477 120.363 33.1968 120.048 33.2654L117.305 33.863C117.19 33.8881 117.1 33.9785 117.075 34.094L116.49 36.8579C116.423 37.1751 115.971 37.1751 115.903 36.8579L115.318 34.094C115.294 33.9785 115.204 33.8881 115.089 33.863L112.346 33.2654C112.031 33.1968 112.031 32.7477 112.346 32.6791L115.089 32.0815C115.204 32.0564 115.294 31.966 115.318 31.8505L115.903 29.0866Z"
            fill="#67CB9C"
          />
          <path
            id="Star 2"
            d="M6.96682 65.3866C7.03396 65.0694 7.48668 65.0694 7.55382 65.3866L8.55666 70.1244C8.5811 70.2399 8.67098 70.3303 8.7863 70.3554L13.4751 71.3768C13.79 71.4454 13.79 71.8945 13.4752 71.9631L8.7863 72.9846C8.67098 73.0097 8.5811 73.1001 8.55666 73.2156L7.55382 77.9533C7.48668 78.2705 7.03396 78.2705 6.96682 77.9533L5.96398 73.2156C5.93954 73.1001 5.84966 73.0097 5.73434 72.9846L1.04549 71.9631C0.730635 71.8945 0.730634 71.4454 1.04549 71.3768L5.73434 70.3554C5.84966 70.3303 5.93954 70.2399 5.96398 70.1244L6.96682 65.3866Z"
            fill="#67CB9C"
          />
          <path
            id="Star 1"
            d="M25.6577 14.9612C25.4838 14.9242 25.4838 14.6759 25.6577 14.639L28.2874 14.0797C28.3508 14.0663 28.4004 14.0169 28.4141 13.9535L28.9804 11.3424C29.0179 11.1693 29.2648 11.1693 29.3023 11.3424L29.8685 13.9535C29.8822 14.0169 29.9318 14.0663 29.9952 14.0797L32.6249 14.639C32.7988 14.6759 32.7988 14.9242 32.6249 14.9612L29.9952 15.5204C29.9318 15.5339 29.8822 15.5832 29.8685 15.6466L29.3023 18.2577C29.2648 18.4308 29.0179 18.4308 28.9804 18.2577L28.4141 15.6466C28.4004 15.5832 28.3508 15.5339 28.2874 15.5204L25.6577 14.9612Z"
            fill="#67CB9C"
          />
        </g>
        <g id="illustration">
          <circle id="Ellipse 2_2" cx="73.2001" cy="43.9" r="33.3" fill="#93DAB8" stroke="#67CB9C" strokeWidth="1.2" />
          <circle id="Ellipse 1_2" cx="73.2" cy="43.9" r="27.9" fill="#E9F8F1" stroke="#67CB9C" strokeWidth="1.2" />
          <path
            id="Vector 7"
            d="M29.7732 105.588L47.7709 80.591L48.5708 79.9911L50.3706 79.5912H52.9703L54.3701 80.591L55.7699 81.191L56.9698 82.5908L57.9696 84.7905L42.3716 114.187L40.5718 115.187H38.3721L35.3725 114.187L32.9728 113.187L30.7731 110.187L29.7732 108.987V105.588Z"
            fill="#93DAB8"
          />
          <path id="Vector 6" d="M61.3693 75.1917L56.8066 82.5908" stroke="#67CB9C" strokeWidth="1.2" />
          <path id="Vector 5" d="M56.0064 72.7921L51.6069 79.3912" stroke="#67CB9C" strokeWidth="1.2" />
          <path id="Vector 4" d="M48.9706 79.5912L29.573 106.188" stroke="#67CB9C" strokeWidth="1.2" />
          <path id="Vector 3" d="M57.7695 84.3905L42.1714 114.587" stroke="#67CB9C" strokeWidth="1.2" />
          <path
            id="Vector 2"
            d="M48.5706 79.9911L48.8997 79.8078C49.6199 79.4068 50.4474 79.2411 51.2664 79.334V79.334C51.6009 79.3719 51.9291 79.4525 52.2431 79.5736L52.8067 79.7911L52.9967 79.8544C54.0554 80.2073 55.0173 80.8018 55.8064 81.5909V81.5909L56.4266 82.2111C56.6787 82.4632 56.899 82.7453 57.0825 83.0511V83.0511C57.2971 83.4089 57.4594 83.7957 57.5642 84.1996L57.7694 84.9904"
            stroke="#67CB9C"
            strokeWidth="1.2"
          />
          <path
            id="Vector 1"
            d="M42.5716 114.187L41.7199 114.57C40.8948 114.941 40.0049 115.147 39.1007 115.175V115.175C38.3984 115.197 37.6969 115.112 37.0203 114.922L36.4466 114.762L36.2542 114.697C34.5541 114.12 33.0059 113.167 31.7245 111.91V111.91L30.9837 111.183C30.4045 110.615 29.9704 109.915 29.718 109.144V109.144C29.4041 108.185 29.3841 107.153 29.6607 106.183L29.7732 105.788"
            stroke="#67CB9C"
            strokeWidth="1.2"
          />
        </g>
      </g>
    </svg>
  )
}
