import { IIcon } from "./interface"

export const TnetLogoIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
      <g id="Group 1">
        <path
          id="Vector"
          d="M16.8971 4.71232C16.4454 4.98319 16.2986 5.58135 16.5696 6.0328C18.3988 9.0575 17.9358 12.9174 15.4292 15.4229C12.9226 17.9284 9.06112 18.4025 6.03513 16.5628C5.58349 16.2919 4.99636 16.4274 4.71408 16.8901C4.4431 17.3416 4.58988 17.9284 5.04152 18.2106C8.82401 20.5017 13.6566 19.9148 16.7842 16.7773C19.9118 13.651 20.4989 8.82049 18.2181 5.03962C17.9471 4.58817 17.3487 4.44145 16.8971 4.71232Z"
        />
        <path
          id="Vector_2"
          d="M4.57866 4.57689C7.08526 2.07135 10.9468 1.59733 13.9728 3.43698C14.3567 3.67399 14.8422 3.59499 15.147 3.29026C15.2035 3.23383 15.2487 3.1774 15.2938 3.10968C15.5648 2.65823 15.418 2.07135 14.9664 1.7892C11.1839 -0.5019 6.35135 0.0849819 3.22374 3.22254C0.084848 6.34882 -0.502284 11.1793 1.78979 14.9602C2.06077 15.4116 2.65919 15.5584 3.11083 15.2875C3.56247 15.0166 3.70926 14.4184 3.43827 13.967C1.59784 10.9423 2.07206 7.08242 4.57866 4.57689Z"
        />
        <path
          id="Vector_3"
          d="M14.1424 12.4771C14.5037 12.6013 14.8876 12.4997 15.136 12.2514C15.2376 12.1498 15.3166 12.037 15.3618 11.8902C16.0844 9.83615 15.5537 7.52248 14.0182 5.98756C12.4826 4.45264 10.1566 3.92218 8.11298 4.6445C7.61617 4.82508 7.35648 5.36682 7.52585 5.86341C7.7065 6.36 8.24847 6.61958 8.74527 6.45029C10.1002 5.97627 11.6358 6.31486 12.6632 7.3419C13.6794 8.35766 14.0295 9.89258 13.5552 11.2582C13.3746 11.7548 13.6343 12.2965 14.1424 12.4771Z"
        />
        <path
          id="Vector_4"
          d="M11.8049 15.3891C11.9516 15.344 12.0758 15.265 12.1775 15.1634C12.4259 14.9151 12.5275 14.5427 12.4146 14.1815C12.2452 13.6736 11.7032 13.4028 11.1951 13.572C9.82893 14.0235 8.3611 13.6736 7.34491 12.6692C6.32872 11.6534 5.9787 10.1749 6.44163 8.82056C6.61099 8.32397 6.34001 7.77095 5.83192 7.60165C5.32382 7.43236 4.78185 7.70323 4.61249 8.21111C3.92374 10.2652 4.45441 12.4999 5.9787 14.0235C7.51427 15.5471 9.74989 16.0663 11.8049 15.3891Z"
        />
        <path
          id="Vector_5"
          d="M8.80138 8.798C8.13522 9.46388 8.13522 10.5361 8.80138 11.202C9.46755 11.8678 10.5402 11.8678 11.2064 11.202C11.8725 10.5361 11.8725 9.46388 11.2064 8.798C10.5402 8.13211 9.46755 8.13211 8.80138 8.798Z"
        />
      </g>
    </svg>
  )
}
