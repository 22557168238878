import { IIcon } from "./interface"

export const FacebookIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="8" height="18" viewBox="0 0 8 18" fill="none" className={className}>
      <g id="Frame" clipPath="url(#clip0_5110_10052)">
        <path
          id="Vector"
          d="M5.32 17.3257V8.82568H7.686L8 5.89568H5.32V4.42968C5.32 3.66568 5.393 3.25668 6.5 3.25668H7.98V0.325684H5.616C2.773 0.325684 1.772 1.74668 1.772 4.13668V5.89568H0V8.82568H1.772V17.3257H5.32Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5110_10052">
          <rect width="8" height="17" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
