import { IIcon } from "./interface"

export const MortgageIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M5.8335 9.66663H4.16683V15.5H5.8335V9.66663ZM10.8335 9.66663H9.16683V15.5H10.8335V9.66663ZM17.9168 17.1666H2.0835V18.8333H17.9168V17.1666ZM15.8335 9.66663H14.1668V15.5H15.8335V9.66663ZM10.0002 4.04996L14.3418 6.33329H5.6585L10.0002 4.04996ZM10.0002 2.16663L2.0835 6.33329V7.99996H17.9168V6.33329L10.0002 2.16663Z"
        fill="#646464"
      />
    </svg>
  )
}
