import { IIcon } from "./interface"

export const TktIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="80" height="19" viewBox="0 0 80 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.4436 0H0V5.45628C2.12401 5.45628 3.84102 7.17329 3.84102 9.2973C3.84102 11.4213 2.12401 13.1383 0 13.1383V18.6073H45.4436"
        fill="#009DF6"
      />
      <path
        d="M45.4434 18.6073H79.9998V13.1383C77.8758 13.1383 76.1588 11.4213 76.1588 9.2973C76.1588 7.17329 77.8758 5.45628 79.9998 5.45628V0H45.4434"
        fill="#FF005F"
      />
      <path d="M13.4689 16.1908H9.7424V5.72335H7.6947V2.41652H15.593V5.72335H13.4562V16.1908H13.4689Z" fill="white" />
      <path
        d="M27.7772 16.1908H23.7581L21.2907 9.84418L21.3924 16.1908H17.6786V2.41652H21.3924L21.2907 8.31795L23.491 2.41652H27.421L24.4449 9.06835L27.7772 16.1908Z"
        fill="white"
      />
      <path d="M35.5484 16.1908H31.8219V5.72335H29.7742V2.41652H37.6724V5.72335H35.5357V16.1908H35.5484Z" fill="white" />
      <path
        d="M58.2256 8.2289H63.4148V8.80123C63.4148 11.4594 63.0205 13.3672 62.232 14.5246C61.3671 15.7965 60.0952 16.4324 58.4037 16.4324C56.7375 16.4324 55.4402 15.8092 54.499 14.5628C53.5579 13.3036 53.0873 11.5739 53.0873 9.37357C53.0873 7.14781 53.5579 5.37993 54.5118 4.09535C55.4529 2.81077 56.7502 2.16212 58.3909 2.16212C60.5658 2.16212 62.1429 3.29408 63.1223 5.57071L59.5229 6.89344C59.472 6.46101 59.3448 6.11761 59.1541 5.86324C58.9633 5.60886 58.7216 5.48168 58.4291 5.48168C57.4116 5.48168 56.8901 6.89344 56.8901 9.70425C56.8901 10.8998 57.0046 11.7647 57.2335 12.3116C57.4625 12.8585 57.8186 13.1256 58.3146 13.1256C58.5054 13.1256 58.6835 13.0747 58.8488 12.9857C59.0142 12.8966 59.1413 12.7567 59.2558 12.5787C59.3703 12.4006 59.4466 12.1971 59.5102 11.9554C59.5738 11.7138 59.5992 11.434 59.5992 11.1287H58.2256V8.2289Z"
        fill="white"
      />
      <path
        d="M65.399 16.1908V2.41652H72.3052V5.65976H69.1129V7.6693H71.9873V10.7854H69.1256V12.8966H72.318V16.2035H65.399V16.1908Z"
        fill="white"
      />
      <path
        d="M45.4437 13.1384C47.565 13.1384 49.2847 11.4187 49.2847 9.29735C49.2847 7.17602 47.565 5.45634 45.4437 5.45634C43.3223 5.45634 41.6027 7.17602 41.6027 9.29735C41.6027 11.4187 43.3223 13.1384 45.4437 13.1384Z"
        fill="white"
      />
    </svg>
  )
}
