import { IIcon } from "./interface"

export const ExclusiveBadgeIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" className={className}>
      <rect width="28" height="28" rx="8" fill="#26B571" />
      <g clipPath="url(#clip0_4130_40092)">
        <path
          d="M14.0002 18.1397L17.1127 20.0222C17.6827 20.3672 18.3802 19.8572 18.2302 19.2122L17.4052 15.6722L20.1577 13.2872C20.6602 12.8522 20.3902 12.0272 19.7302 11.9747L16.1077 11.6672L14.6902 8.3222C14.4352 7.7147 13.5652 7.7147 13.3102 8.3222L11.8927 11.6597L8.27024 11.9672C7.61024 12.0197 7.34024 12.8447 7.84274 13.2797L10.5952 15.6647L9.77024 19.2047C9.62024 19.8497 10.3177 20.3597 10.8877 20.0147L14.0002 18.1397Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4130_40092">
          <rect width="16" height="16" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}
