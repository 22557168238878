import { IIcon } from "./interface"

export const HelpIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M9.1665 15.5H10.8332V13.8334H9.1665V15.5ZM9.99984 2.16669C5.39984 2.16669 1.6665 5.90002 1.6665 10.5C1.6665 15.1 5.39984 18.8334 9.99984 18.8334C14.5998 18.8334 18.3332 15.1 18.3332 10.5C18.3332 5.90002 14.5998 2.16669 9.99984 2.16669ZM9.99984 17.1667C6.32484 17.1667 3.33317 14.175 3.33317 10.5C3.33317 6.82502 6.32484 3.83335 9.99984 3.83335C13.6748 3.83335 16.6665 6.82502 16.6665 10.5C16.6665 14.175 13.6748 17.1667 9.99984 17.1667ZM9.99984 5.50002C8.15817 5.50002 6.6665 6.99169 6.6665 8.83335H8.33317C8.33317 7.91669 9.08317 7.16669 9.99984 7.16669C10.9165 7.16669 11.6665 7.91669 11.6665 8.83335C11.6665 10.5 9.1665 10.2917 9.1665 13H10.8332C10.8332 11.125 13.3332 10.9167 13.3332 8.83335C13.3332 6.99169 11.8415 5.50002 9.99984 5.50002Z"
        fill="#646464"
      />
    </svg>
  )
}
