import { IIcon } from "./interface"

export const WalletIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path d="M17.0833 6.56667V4.66667C17.0833 3.75 16.3333 3 15.4166 3H3.74992C2.82492 3 2.08325 3.75 2.08325 4.66667V16.3333C2.08325 17.25 2.82492 18 3.74992 18H15.4166C16.3333 18 17.0833 17.25 17.0833 16.3333V14.4333C17.5749 14.1417 17.9166 13.6167 17.9166 13V8C17.9166 7.38333 17.5749 6.85833 17.0833 6.56667ZM16.2499 8V13H10.4166V8H16.2499ZM3.74992 16.3333V4.66667H15.4166V6.33333H10.4166C9.49992 6.33333 8.74992 7.08333 8.74992 8V13C8.74992 13.9167 9.49992 14.6667 10.4166 14.6667H15.4166V16.3333H3.74992Z" />
      <path d="M12.9166 11.75C13.6069 11.75 14.1666 11.1904 14.1666 10.5C14.1666 9.80964 13.6069 9.25 12.9166 9.25C12.2262 9.25 11.6666 9.80964 11.6666 10.5C11.6666 11.1904 12.2262 11.75 12.9166 11.75Z" />
    </svg>
  )
}
