import { IIcon } from "./interface"

export const CallRequestIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M16.6667 13.6042C15.6333 13.6042 14.625 13.4375 13.6917 13.1292C13.6083 13.0959 13.5167 13.0875 13.4333 13.0875C13.2167 13.0875 13.0083 13.1709 12.8417 13.3292L11.0083 15.1625C8.65 13.9542 6.71667 12.0292 5.51667 9.67086L7.35 7.83753C7.58333 7.6042 7.65 7.27919 7.55833 6.98753C7.25 6.0542 7.08333 5.0542 7.08333 4.01253C7.08333 3.5542 6.70833 3.1792 6.25 3.1792H3.33333C2.875 3.1792 2.5 3.5542 2.5 4.01253C2.5 11.8375 8.84167 18.1792 16.6667 18.1792C17.125 18.1792 17.5 17.8042 17.5 17.3459V14.4375C17.5 13.9792 17.125 13.6042 16.6667 13.6042ZM4.19167 4.84586H5.44167C5.5 5.58753 5.625 6.31253 5.825 7.0042L4.825 8.0042C4.48333 7.0042 4.26667 5.94586 4.19167 4.84586ZM15.8333 16.4875C14.7333 16.4125 13.675 16.1959 12.6667 15.8625L13.6583 14.8709C14.3667 15.0709 15.0917 15.1959 15.825 15.2459V16.4875H15.8333ZM15 8.1792H12.8417L17.025 3.99586L15.85 2.82086L11.6667 7.0042V4.84586H10V9.84586H15V8.1792Z"
        fill="#646464"
      />
    </svg>
  )
}
