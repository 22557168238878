import React from "react"
import { IIcon } from "../interface"

export const BuildingIcon3: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="28" viewBox="0 0 20 28" fill="none" className={className}>
      <path
        id="Vector"
        d="M1 27H19M7 14H8M7 18H8M7 22H8M12 14C12 14 12.6095 14 13 14M12 18H13M12 22H13M3 27V3C3 2.46957 3.21071 1.96086 3.58579 1.58579C3.96086 1.21071 4.46957 1 5 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V27M12 10H13M7 10H8M12 6H13M7 6H8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
