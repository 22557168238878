import { IIcon } from "./interface"

export const ClearLocationsSmallIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" className={className}>
      <path
        d="M4.35619 0.342102H13.9995C14.1763 0.342102 14.3459 0.414188 14.4709 0.542503C14.596 0.670817 14.6662 0.844849 14.6662 1.02631V11.9737C14.6662 12.1551 14.596 12.3292 14.4709 12.4575C14.3459 12.5858 14.1763 12.6579 13.9995 12.6579H4.35619C4.24647 12.6579 4.13843 12.6301 4.04167 12.577C3.94491 12.5239 3.86243 12.4471 3.80153 12.3534L0.246194 6.87973C0.173101 6.7673 0.134094 6.63516 0.134094 6.5C0.134094 6.36483 0.173101 6.2327 0.246194 6.12026L3.80153 0.646576C3.86243 0.552896 3.94491 0.476093 4.04167 0.422979C4.13843 0.369866 4.24647 0.342085 4.35619 0.342102ZM4.71286 1.71052L1.60219 6.5L4.71286 11.2895H13.3329V1.71052H4.71286ZM10.6662 5.81579V7.18421H5.99953V5.81579H10.6662Z"
        fill="#909090"
      />
    </svg>
  )
}
