import { IIcon } from "./interface"

export const UserIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="#222222" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.2497 11.8871C1.99984 11.1057 3.01726 10.6667 4.07813 10.6667H10.4782C11.539 10.6667 12.5565 11.1057 13.3066 11.8871C14.0567 12.6684 14.4782 13.7283 14.4782 14.8334V16.5C14.4782 16.9603 14.12 17.3334 13.6782 17.3334C13.2363 17.3334 12.8782 16.9603 12.8782 16.5V14.8334C12.8782 14.1704 12.6253 13.5344 12.1752 13.0656C11.7251 12.5968 11.1147 12.3334 10.4782 12.3334H4.07813C3.44161 12.3334 2.83116 12.5968 2.38107 13.0656C1.93098 13.5344 1.67813 14.1704 1.67813 14.8334V16.5C1.67813 16.9603 1.31995 17.3334 0.878125 17.3334C0.436301 17.3334 0.078125 16.9603 0.078125 16.5V14.8334C0.078125 13.7283 0.499549 12.6684 1.2497 11.8871Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.27835 2.33335C5.95283 2.33335 4.87832 3.45265 4.87832 4.83335C4.87832 6.21407 5.95283 7.33335 7.27835 7.33335C8.60387 7.33335 9.67835 6.21407 9.67835 4.83335C9.67835 3.45265 8.60387 2.33335 7.27835 2.33335ZM3.27832 4.83335C3.27832 2.53217 5.06915 0.666687 7.27835 0.666687C9.48747 0.666687 11.2784 2.53217 11.2784 4.83335C11.2784 7.13454 9.48747 9.00002 7.27835 9.00002C5.06915 9.00002 3.27832 7.13454 3.27832 4.83335Z"
        fill="#222222"
      />
    </svg>
  )
}
