import * as Icn from "../assets"
import React, { ComponentType } from "react"

type Props = {
  name: keyof typeof Icn
  className?: string
}

export const Icon: React.FC<Props> = ({ name, className }) => {
  if (!name) {
    return null
  }

  const IconComponent = Icn[name] as ComponentType<{
    className?: string
  }>

  return <IconComponent className={className} />
}

export type IAllIconsObject = typeof Icn
