import { IIcon } from "./interface"

export const CallIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M5.45 4.66667C5.5 5.40833 5.625 6.13333 5.825 6.825L4.825 7.825C4.48333 6.825 4.26667 5.76667 4.19167 4.66667H5.45ZM13.6667 14.6833C14.375 14.8833 15.1 15.0083 15.8333 15.0583V16.3C14.7333 16.225 13.675 16.0083 12.6667 15.675L13.6667 14.6833ZM6.25 3H3.33333C2.875 3 2.5 3.375 2.5 3.83333C2.5 11.6583 8.84167 18 16.6667 18C17.125 18 17.5 17.625 17.5 17.1667V14.2583C17.5 13.8 17.125 13.425 16.6667 13.425C15.6333 13.425 14.625 13.2583 13.6917 12.95C13.6083 12.9167 13.5167 12.9083 13.4333 12.9083C13.2167 12.9083 13.0083 12.9917 12.8417 13.15L11.0083 14.9833C8.65 13.775 6.71667 11.85 5.51667 9.49167L7.35 7.65833C7.58333 7.425 7.65 7.1 7.55833 6.80833C7.25 5.875 7.08333 4.875 7.08333 3.83333C7.08333 3.375 6.70833 3 6.25 3Z"
        fill="#646464"
      />
    </svg>
  )
}
