import React from "react"
import { IIcon } from "./interface"

export const ClearLocationsIcon = ({ className }: IIcon) => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" className={className}>
      <path
        d="M6.53432 1.11994e-08H20.9993C21.2645 1.11994e-08 21.5189 0.105357 21.7064 0.292893C21.894 0.48043 21.9993 0.734784 21.9993 1V17C21.9993 17.2652 21.894 17.5196 21.7064 17.7071C21.5189 17.8946 21.2645 18 20.9993 18H6.53432C6.36973 18 6.20768 17.9594 6.06254 17.8818C5.9174 17.8042 5.79367 17.6919 5.70232 17.555L0.369322 9.555C0.259683 9.39067 0.201172 9.19755 0.201172 9C0.201172 8.80245 0.259683 8.60933 0.369322 8.445L5.70232 0.445C5.79367 0.308084 5.9174 0.195832 6.06254 0.118205C6.20768 0.0405779 6.36973 -2.46193e-05 6.53432 1.11994e-08ZM7.06932 2L2.40332 9L7.06932 16H19.9993V2H7.06932ZM15.9993 8V10H8.99932V8H15.9993Z"
        fill="#909090"
      />
    </svg>
  )
}
