import React from "react"
import { IIcon } from "../interface"

export const BuildingIcon11: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="60" viewBox="0 0 20 60" fill="none" className={className}>
      <path
        id="Vector"
        d="M1 59H19M7 46H8M7 50H8M7 54H8M12 46C12 46 12.6095 46 13 46M12 50H13M12 54H13M3 59V3C3 2.46957 3.21071 1.96086 3.58579 1.58579C3.96086 1.21071 4.46957 1 5 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V59M12 42H13M7 42H8M12 38H13M7 38H8M12 34H13M7 34H8M12 30H13M7 30H8M12 26H13M7 26H8M12 22H13M7 22H8M12 18H13M7 18H8M12 14H13M7 14H8M12 10H13M7 10H8M12 6H13M7 6H8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
