import { IIcon } from "./interface"

export const MetroIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" className={className}>
      <path
        d="M10.6896 4.78667L7.9643 10.4133L5.2743 4.78667L3.04096 11.574H2.6543V12.33H4.92296V11.6093H4.57096L5.67963 8.56667L7.68363 12.3293H8.22896L10.233 8.53133L11.3583 11.5733H10.9543V12.3293H13.275V11.5733H12.8703L10.6896 4.78667Z"
        fill="#FF5353"
      />
      <path
        d="M7.91187 1.83333C6.86443 1.83202 5.82726 2.03972 4.8611 2.44428C3.89494 2.84883 3.01921 3.4421 2.28521 4.18933C0.809205 5.70133 -0.000127954 7.688 0.017872 9.78067C0.017872 11.6093 0.668539 13.4027 1.84654 14.8273C1.87752 14.8674 1.91767 14.8995 1.96361 14.9208C2.00954 14.9421 2.05993 14.9522 2.11054 14.95H4.43121C6.20721 14.95 8.49254 14.9673 10.3912 14.9673C11.9392 14.9673 13.2399 14.9673 13.8205 14.95C13.8723 14.9486 13.9231 14.9352 13.9688 14.9108C14.0145 14.8864 14.0539 14.8516 14.0839 14.8093C15.238 13.381 15.8651 11.599 15.8599 9.76267C15.8592 5.40333 12.2899 1.83333 7.91254 1.83333H7.91187ZM13.6265 14.2467C12.1139 14.282 7.31387 14.264 4.41321 14.2467H2.26787C1.16982 12.8642 0.61702 11.127 0.71421 9.36414C0.811401 7.60132 1.55183 5.93539 2.79521 4.682C4.16721 3.31 5.97787 2.53667 7.91187 2.53667C11.9032 2.53667 15.1559 5.79 15.1559 9.78067C15.163 11.399 14.6242 12.9724 13.6265 14.2467Z"
        fill="#FF5353"
      />
    </svg>
  )
}
