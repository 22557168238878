import { IIcon } from "./interface"

export const FloorIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#646464" className={className}>
      <g clipPath="url(#clip0_7004_36145)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1H4C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V4C15 2.34315 13.6569 1 12 1ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4Z"
        />
        <path d="M4 13V12.0526H5.65789V9.92105H7.78947V7.78947H9.92105V5.65789H12.0526V4H13V6.60526H10.8684V8.73684H8.73684V10.8684H6.60526V13H4Z" />
      </g>
      <defs>
        <clipPath id="clip0_7004_36145">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
