import { IIcon } from "./interface"

export const RocketLaunchIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="33" height="40" viewBox="0 0 33 40" fill="#FD541A" className={className}>
      <g clipPath="url(#clip0_7196_40630)">
        <path
          d="M12.0385 10.3697C8.63845 14.1863 6.30512 19.6697 6.08845 20.1863L2.32179 18.5697C1.23845 18.103 0.971788 16.6863 1.80512 15.853L6.82179 10.8363C7.60512 10.053 8.73845 9.70301 9.83846 9.91968L12.0385 10.3697ZM14.5218 27.303C15.0218 27.803 15.7551 27.9363 16.3885 27.6363C18.3218 26.7363 22.4718 24.6197 25.1551 21.9363C32.8051 14.2863 32.8718 8.05301 32.4218 5.38634C32.3051 4.71968 31.7718 4.18634 31.1051 4.06968C28.4385 3.61968 22.2051 3.68634 14.5551 11.3363C11.8718 14.0197 9.77179 18.1697 8.85512 20.103C8.55512 20.7363 8.70512 21.4863 9.18846 21.9697L14.5218 27.303ZM26.1385 24.4697C22.3218 27.8697 16.8385 30.203 16.3218 30.4197L17.9385 34.1863C18.4051 35.2697 19.8218 35.5363 20.6551 34.703L25.6718 29.6863C26.4551 28.903 26.8051 27.7697 26.5885 26.6697L26.1385 24.4697ZM11.6218 28.803C11.9551 30.5697 11.3718 32.203 10.2551 33.3197C8.97179 34.603 4.98846 35.553 2.40512 36.053C1.25512 36.2697 0.238455 35.253 0.455122 34.103C0.955122 31.5197 1.88846 27.5363 3.18846 26.253C4.30512 25.1363 5.93846 24.553 7.70512 24.8863C9.65512 25.253 11.2551 26.853 11.6218 28.803ZM18.3885 14.7863C18.3885 12.953 19.8885 11.453 21.7218 11.453C23.5551 11.453 25.0551 12.953 25.0551 14.7863C25.0551 16.6197 23.5551 18.1197 21.7218 18.1197C19.8885 18.1197 18.3885 16.6197 18.3885 14.7863Z"
          fill="#FD541A"
        />
      </g>
      <defs>
        <clipPath id="clip0_7196_40630">
          <rect width="32" height="40" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
