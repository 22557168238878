import { IIcon } from "./interface"

export const ParkingIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className={className}>
      <g clipPath="url(#clip0_6771_10134)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.91758 5.10958C10.4643 5.431 10.791 6.03578 10.791 6.95814C10.791 7.8805 10.4643 8.48528 9.91758 8.80669C9.45221 9.08028 8.92207 9.07945 8.66522 9.07905C8.6558 9.07904 8.64675 9.07902 8.63808 9.07902H7.07011V10.6046C7.07011 10.9128 6.82023 11.1627 6.51199 11.1627C6.20374 11.1627 5.95386 10.9128 5.95386 10.6046V5.39538C5.95386 5.08713 6.20374 4.83725 6.51199 4.83725H8.63808C8.64675 4.83725 8.6558 4.83724 8.66522 4.83722C8.92207 4.83682 9.45221 4.83599 9.91758 5.10958ZM7.07011 7.96277H8.63808C8.92439 7.96277 9.16973 7.95148 9.35185 7.84441C9.46968 7.77514 9.67471 7.59853 9.67471 6.95814C9.67471 6.31774 9.46968 6.14114 9.35185 6.07186C9.16973 5.96479 8.92439 5.95351 8.63808 5.95351H7.07011V7.96277Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99984 1.11626C4.19814 1.11626 1.11626 4.19814 1.11626 7.99984C1.11626 11.8015 4.19814 14.8834 7.99984 14.8834C11.8015 14.8834 14.8834 11.8015 14.8834 7.99984C14.8834 4.19814 11.8015 1.11626 7.99984 1.11626ZM0 7.99984C0 3.58165 3.58165 0 7.99984 0C12.418 0 15.9997 3.58165 15.9997 7.99984C15.9997 12.418 12.418 15.9997 7.99984 15.9997C3.58165 15.9997 0 12.418 0 7.99984Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6771_10134">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
