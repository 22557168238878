import React from "react"
import { IIcon } from "../interface"

export const BuildingIcon2: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" className={className}>
      <path
        d="M1 23H19M7 10H8M7 14H8M7 18H8M12 10C12 10 12.6095 10 13 10M12 14H13M12 18H13M3 23V3C3 2.46957 3.21071 1.96086 3.58579 1.58579C3.96086 1.21071 4.46957 1 5 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V23M12 6H13M7 6H8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
