import { IIcon } from "./interface"

export const ChevronDownV2Icon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
      <rect width="24" height="24" rx="12" fill="#EEEEEE" />
      <path
        d="M14.7741 9.7221L11.9964 12.6682L9.21879 9.7221C8.9396 9.42597 8.48859 9.42597 8.2094 9.7221C7.9302 10.0182 7.9302 10.4966 8.2094 10.7927L11.4953 14.2779C11.7745 14.574 12.2255 14.574 12.5047 14.2779L15.7906 10.7927C16.0698 10.4966 16.0698 10.0182 15.7906 9.7221C15.5114 9.43356 15.0532 9.42597 14.7741 9.7221Z"
        fill="#222222"
      />
    </svg>
  )
}
