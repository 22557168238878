import { IIcon } from "./interface"

export const VipIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="49" height="28" viewBox="0 0 49 28" fill="none" className={className}>
      <rect width="49" height="28" rx="8" fill="#3C74FF" />
      <path
        d="M20.4 8.528H22.766L18.356 19H16.536L12.126 8.528H14.478L17.446 15.668L20.4 8.528ZM23.7541 8.528H25.9241V19H23.7541V8.528ZM27.9377 8.528H32.4737C34.4617 8.528 35.8477 9.746 35.8477 11.902C35.8477 14.058 34.4617 15.276 32.4737 15.276H30.1077V19H27.9377V8.528ZM32.1937 10.432H30.0937V13.358H32.1937C33.0337 13.358 33.6497 12.854 33.6497 11.902C33.6497 10.95 33.0337 10.432 32.1937 10.432Z"
        fill="white"
      />
    </svg>
  )
}
