import { IIcon } from "./interface"

export const BuildingV1Icon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M9.99984 6.33333V4.66667C9.99984 3.75 9.24984 3 8.33317 3H3.33317C2.4165 3 1.6665 3.75 1.6665 4.66667V16.3333C1.6665 17.25 2.4165 18 3.33317 18H16.6665C17.5832 18 18.3332 17.25 18.3332 16.3333V8C18.3332 7.08333 17.5832 6.33333 16.6665 6.33333H9.99984ZM8.33317 16.3333H3.33317V14.6667H8.33317V16.3333ZM8.33317 13H3.33317V11.3333H8.33317V13ZM8.33317 9.66667H3.33317V8H8.33317V9.66667ZM8.33317 6.33333H3.33317V4.66667H8.33317V6.33333ZM16.6665 16.3333H9.99984V8H16.6665V16.3333ZM14.9998 9.66667H11.6665V11.3333H14.9998V9.66667ZM14.9998 13H11.6665V14.6667H14.9998V13Z"
        fill="#646464"
      />
    </svg>
  )
}
