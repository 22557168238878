import { IIcon } from "./interface"

export const VisibilityOffIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" className={className}>
      <path
        d="M14.0009 16.3333C14.0476 16.3333 14.0943 16.3217 14.1409 16.3217L11.0959 13.2767C11.0959 13.3233 11.0843 13.37 11.0843 13.4167C11.0843 15.0267 12.3909 16.3333 14.0009 16.3333ZM15.1793 10.745L16.6726 12.2383C16.3693 11.5733 15.8443 11.0367 15.1793 10.745ZM24.2909 13.4167C22.3659 9.485 18.4226 7 14.0009 7C13.2076 7 12.4376 7.105 11.6793 7.25667L12.7526 8.33C13.1609 8.225 13.5693 8.16667 14.0009 8.16667C16.8943 8.16667 19.2509 10.5233 19.2509 13.4167C19.2509 13.8483 19.1809 14.2567 19.0876 14.665L21.4793 17.0567C22.6226 16.0533 23.5909 14.8283 24.2909 13.4167ZM14.0009 19.8333C15.1093 19.8333 16.1826 19.6817 17.2093 19.3783L16.0659 18.235C15.4359 18.515 14.7359 18.6667 14.0009 18.6667C11.1076 18.6667 8.75094 16.31 8.75094 13.4167C8.75094 12.6817 8.9026 11.9817 9.17094 11.3517L7.12927 9.29833C5.70594 10.36 4.5276 11.7483 3.71094 13.405C5.63594 17.3367 9.57927 19.8333 14.0009 19.8333Z"
        fill="black"
        fillOpacity="0"
      />
      <path d="M13.9993 7C18.421 7 22.3643 9.485 24.2894 13.4167C23.601 14.84 22.6327 16.065 21.4777 17.0567L23.1227 18.7017C24.7444 17.2667 26.0277 15.47 26.8327 13.4167C24.8144 8.295 19.8327 4.66667 13.9993 4.66667C12.5177 4.66667 11.0943 4.9 9.75268 5.33167L11.6777 7.25667C12.436 7.105 13.206 7 13.9993 7ZM16.6593 12.2383L19.0744 14.6533C19.1677 14.2567 19.2377 13.8367 19.2377 13.405C19.2493 10.5117 16.8927 8.16667 13.9993 8.16667C13.5677 8.16667 13.1594 8.23667 12.751 8.33L15.166 10.745C15.8427 11.0367 16.3677 11.5733 16.6593 12.2383ZM2.34435 4.515L5.47102 7.64167C3.56935 9.135 2.06435 11.1183 1.16602 13.4167C3.18435 18.5383 8.16602 22.1667 13.9993 22.1667C15.7727 22.1667 17.476 21.8283 19.0394 21.21L23.0294 25.2L24.6744 23.555L3.98935 2.85834L2.34435 4.515ZM11.0943 13.265L14.1393 16.31C14.0927 16.3217 14.046 16.3333 13.9993 16.3333C12.3893 16.3333 11.0827 15.0267 11.0827 13.4167C11.0827 13.3583 11.0943 13.3233 11.0943 13.265ZM7.12768 9.29834L9.16935 11.34C8.90102 11.9817 8.74935 12.6817 8.74935 13.4167C8.74935 16.31 11.106 18.6667 13.9993 18.6667C14.7344 18.6667 15.4344 18.515 16.0644 18.2467L17.2077 19.39C16.181 19.67 15.1077 19.8333 13.9993 19.8333C9.57768 19.8333 5.63435 17.3483 3.70935 13.4167C4.52602 11.7483 5.71602 10.3717 7.12768 9.29834Z" />
    </svg>
  )
}
