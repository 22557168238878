import { IIcon } from "./interface"

export const MeasurementV1Icon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M15.0118 14.9227L14.4201 15.5144C14.2534 15.681 13.9951 15.681 13.8284 15.5144C13.6618 15.3477 13.6618 15.0894 13.8284 14.9227L14.4201 14.331L12.8034 12.7144L12.2118 13.306C12.0451 13.4727 11.7868 13.4727 11.6201 13.306C11.4534 13.1394 11.4534 12.881 11.6201 12.7144L12.2118 12.1227L10.5951 10.506L10.0034 11.0977C9.83675 11.2644 9.57842 11.2644 9.41175 11.0977C9.24508 10.931 9.24508 10.6727 9.41175 10.506L10.0034 9.91438L8.37842 8.28938L7.78675 8.88105C7.62008 9.04771 7.36175 9.04771 7.19508 8.88105C7.02842 8.71438 7.02842 8.45605 7.19508 8.28938L7.78675 7.69771L6.17008 6.08105L5.57842 6.67271C5.41175 6.83938 5.15342 6.83938 4.98675 6.67271C4.82008 6.50605 4.82008 6.24771 4.98675 6.08105L5.57842 5.48938L4.33675 4.24771C4.07842 3.98938 3.62842 4.17271 3.62842 4.54771V15.206C3.62842 16.1227 4.37842 16.8727 5.29508 16.8727H15.9534C16.3284 16.8727 16.5118 16.4227 16.2451 16.1644L15.0118 14.9227ZM6.12842 13.5394V9.57271L10.9284 14.3727H6.96175C6.50342 14.3727 6.12842 13.9977 6.12842 13.5394Z"
        fill="#646464"
      />
    </svg>
  )
}
