import { IIcon } from "./interface"

export const SuperAppIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="65" height="20" viewBox="0 0 65 20" fill="none" className={className}>
      <path
        d="M12.736 15.6105C12.8879 15.5597 13.0194 15.4761 13.1255 15.3701C13.3847 15.1107 13.491 14.7177 13.3669 14.3462C13.1919 13.8228 12.6259 13.5403 12.1025 13.7153C10.6836 14.1895 9.14515 13.8271 8.08749 12.7693C7.03021 11.7119 6.66793 10.1737 7.14206 8.75485C7.31703 8.23142 7.03444 7.66548 6.51121 7.49051C5.98778 7.31553 5.42165 7.59841 5.24696 8.12136C4.53097 10.2635 5.07801 12.5859 6.67442 14.1823C8.27131 15.7793 10.594 16.3266 12.736 15.6106V15.6105Z"
        fill="#3C74FF"></path>
      <path
        d="M5.20543 4.34773C7.81444 1.73881 11.8381 1.24831 14.9904 3.15498C15.3919 3.39778 15.8961 3.32488 16.2137 3.00719C16.2696 2.95131 16.3197 2.88781 16.3624 2.81716C16.648 2.34501 16.4969 1.73072 16.0246 1.44511C12.0835 -0.938786 7.05349 -0.326184 3.79245 2.93476C0.530572 6.19664 -0.0819364 11.2268 2.30281 15.1672C2.5886 15.6393 3.20299 15.7904 3.67496 15.5046C4.1473 15.2191 4.29809 14.6047 4.01249 14.1325C2.10516 10.9811 2.59575 6.95731 5.20543 4.34764V4.34773Z"
        fill="#3C74FF"></path>
      <path
        d="M18.0393 4.49581C17.5664 4.7817 17.4161 5.3959 17.7018 5.86796C19.6091 9.0193 19.1189 13.0425 16.5096 15.6519C13.9003 18.2611 9.87693 18.7515 6.72558 16.8442C6.25353 16.5586 5.63961 16.7083 5.35344 17.1817C5.06783 17.6537 5.21882 18.268 5.69097 18.5538C9.6313 20.9386 14.6612 20.3263 17.9225 17.065C21.184 13.8035 21.7962 8.77368 19.4115 4.83335C19.1256 4.36119 18.5113 4.21011 18.0393 4.49581Z"
        fill="#3C74FF"></path>
      <path
        d="M15.1628 12.5808C15.5377 12.7127 15.9383 12.6078 16.2017 12.3444C16.3041 12.2422 16.3857 12.1157 16.437 11.97C17.1891 9.83276 16.6408 7.41744 15.04 5.8167C13.4388 4.21539 11.0234 3.66751 8.88692 4.42066C8.3667 4.6041 8.09342 5.17465 8.27677 5.69506C8.46021 6.21557 9.03085 6.48866 9.55136 6.30512C10.9667 5.80616 12.5664 6.1691 13.627 7.22958C14.6875 8.28997 15.0506 9.89033 14.5521 11.3065C14.3689 11.8271 14.6424 12.3976 15.1629 12.5807L15.1628 12.5808Z"
        fill="#3C74FF"></path>
      <path
        d="M9.60403 8.74661C8.91194 9.4388 8.91194 10.5611 9.60403 11.2532C10.2962 11.9454 11.4185 11.9454 12.1106 11.2532C12.8028 10.5611 12.8028 9.4388 12.1106 8.74661C11.4185 8.05452 10.2962 8.05452 9.60403 8.74661Z"
        fill="#3C74FF"></path>
      <path
        d="M38.6422 15.1078H34.9748V12.7592H38.6422V15.1078ZM37.9308 11.1694H33.3856V17.8158C33.3856 18.2544 33.7413 18.6101 34.18 18.6101C34.6186 18.6099 34.9747 18.2546 34.9749 17.8158V16.6976H37.9308C39.201 16.6976 40.2314 15.6679 40.2314 14.397V13.47C40.2314 12.1992 39.201 11.1694 37.9308 11.1694Z"
        fill="#3C74FF"></path>
      <path
        d="M46.4082 15.1078H42.7408V12.7592H46.4082V15.1078ZM45.6969 11.1694H41.1517V17.8158C41.1517 18.2544 41.5074 18.6101 41.9459 18.6101C42.3847 18.6099 42.7407 18.2546 42.7408 17.8158V16.6976H45.6968C46.9669 16.6976 47.9974 15.6679 47.9974 14.397V13.47C47.9974 12.1992 46.9669 11.1694 45.6968 11.1694H45.6969Z"
        fill="#3C74FF"></path>
      <path
        d="M27.8551 15.2059L28.7082 12.759H30.7134V15.2059H27.8551ZM28.784 11.1692C28.1444 11.1692 27.5705 11.5618 27.3386 12.1578L25.2771 17.4579C25.0611 18.013 25.4707 18.6117 26.0662 18.6117C26.4264 18.6117 26.7472 18.3837 26.8659 18.0436L27.3277 16.7185H30.7134V17.8169C30.7134 18.2558 31.0691 18.6117 31.508 18.6117C31.9468 18.6117 32.3025 18.2559 32.3025 17.8169V11.1692H28.784Z"
        fill="#3C74FF"></path>
      <path
        d="M33.6406 6.53002V2.18272C33.6406 1.74387 33.9963 1.38818 34.4353 1.38818C34.8741 1.38818 35.2298 1.74387 35.2298 2.18272V7.24083H39.5259V2.18272C39.5259 1.74387 39.8816 1.38818 40.3205 1.38818C40.7593 1.38818 41.1151 1.74387 41.1151 2.18272V6.53002C41.1151 7.80056 40.085 8.83066 38.8144 8.83066H35.8167C34.5462 8.83066 33.6407 7.80056 33.6407 6.53002H33.6406Z"
        fill="#3C74FF"></path>
      <path
        d="M51.5396 3.06132V4.35652H56.7963V5.94578H51.5396V7.24098H56.7963V8.83081H52.2511C50.9809 8.83081 49.9504 7.80099 49.9504 6.53016V3.77232C49.9504 2.5014 50.9809 1.47168 52.2511 1.47168H56.7963V3.06151H51.5396V3.06132Z"
        fill="#3C74FF"></path>
      <path
        d="M47.4894 5.39817H43.8225V3.04889H47.4894V5.39817ZM46.778 1.45972H42.2328V8.10624C42.2328 8.54481 42.5885 8.90059 43.0277 8.90059C43.466 8.90041 43.8226 8.54509 43.8226 8.10624V6.98809H46.778C48.0482 6.98809 49.0786 5.95837 49.0786 4.68745V3.76046C49.0786 2.48963 48.0483 1.45981 46.778 1.45981V1.45972Z"
        fill="#3C74FF"></path>
      <path
        d="M63.2679 5.39817H59.6005V3.06131H63.2679V5.39817ZM64.857 4.68735V3.76036C64.857 2.48953 63.8267 1.45972 62.5565 1.45972H58.0112V8.9005H59.6005V6.988H61.0538L62.7994 8.9005H64.8172L63.0652 6.93005C64.0907 6.69873 64.857 5.78283 64.857 4.68735Z"
        fill="#3C74FF"></path>
      <path
        d="M27.4981 3.04906V4.35611H30.8517C31.9028 4.35611 32.7548 5.20803 32.7548 6.25911V6.52966C32.7548 7.80021 31.7247 8.83021 30.4542 8.83021H25.9712V7.24038H31.1655V5.93343L27.8162 5.94095C26.7635 5.9433 25.9089 5.09063 25.9089 4.03795V3.75987C25.9089 2.48895 26.9393 1.45923 28.2095 1.45923H32.4109V3.04906H27.498H27.4981Z"
        fill="#3C74FF"></path>
    </svg>
  )
}
