import { IIcon } from "./interface"

export const InvesteBadgeIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" className={className}>
      <rect width="28" height="28" rx="8" fill="#FCC000" />
      <path
        d="M9.472 13.9145C9.472 16.8763 11.488 18.689 14.352 18.689H19.264V20H9.088V18.7861H10.992C9.136 17.9931 8 16.1642 8 13.8173C8 10.8555 9.648 8.76763 12.032 8.02312V6H12.976V7.81272C13.312 7.76416 13.664 7.73179 14.016 7.73179C14.384 7.73179 14.736 7.76416 15.072 7.81272V6H16.016V8.03931C18.368 8.78382 20 10.904 20 13.9468H18.528C18.528 11.5353 17.52 10.0301 16.016 9.38266V13.9468H15.072V9.09133C14.736 9.02659 14.384 8.99422 14.016 8.99422C13.664 8.99422 13.312 9.02659 12.976 9.09133V13.9468H12.032V9.36647C10.496 10.0139 9.472 11.5029 9.472 13.9145Z"
        fill="white"
        stroke="white"
      />
    </svg>
  )
}
