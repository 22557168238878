import { IIcon } from "./interface"

export const MyShopIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="69" height="12" viewBox="0 0 69 12" fill="none" className={className}>
      <path
        d="M10.9948 11.6605V2.08557L6.59991 11.6605H5.92017L1.53704 2.23793V11.6605H0.8573V0.38623H2.20506L6.64679 9.96116L10.9948 0.38623H12.3542V11.6605H10.9948Z"
        fill="#292728"></path>
      <path
        d="M18.7295 11.6605V7.25393L13.8541 0.38623H15.4597L19.6905 6.17572L23.8744 0.38623H24.6831L20.089 6.77342V11.6488H18.7295V11.6605Z"
        fill="#292728"></path>
      <path
        d="M27.0507 11.5902C26.535 11.4144 26.0897 11.1917 25.7381 10.9339C25.3748 10.6878 25.1052 10.4417 24.8826 10.1838L25.3748 9.72678C26.1952 10.6761 27.3788 11.1566 28.9493 11.18C29.6759 11.18 30.2853 11.0863 30.7775 10.8753C31.2698 10.6644 31.6213 10.3831 31.8675 10.0549C32.1136 9.70334 32.2308 9.34004 32.2308 8.92985C32.2308 8.47278 32.0667 8.09776 31.7737 7.79305C31.469 7.50006 31.0705 7.24223 30.5783 7.04299C30.0861 6.82032 29.5587 6.6328 28.9962 6.44529C27.789 6.04682 26.9101 5.66008 26.3475 5.28505C25.5271 4.73423 25.1052 4.00761 25.1052 3.1052C25.1052 2.23795 25.5154 1.52305 26.3124 0.97223C27.121 0.421408 28.1523 0.140137 29.4063 0.140137C29.9923 0.140137 30.5314 0.233894 31.0236 0.421408C31.5159 0.597202 31.9495 0.819875 32.3011 1.06599C32.6644 1.30038 32.9222 1.54649 33.1215 1.75744L32.6175 2.21451L32.3948 2.00356C32.2425 1.8512 32.0315 1.69885 31.762 1.51133C31.4924 1.33554 31.1526 1.15974 30.7658 1.01911C30.3673 0.878473 29.9337 0.808155 29.4298 0.808155C28.5391 0.808155 27.8242 0.97223 27.2968 1.32382C26.7694 1.68713 26.5116 2.09731 26.5116 2.60126C26.5116 3.08176 26.6639 3.48023 26.9804 3.78494C27.2851 4.07793 27.6953 4.33576 28.1875 4.55843C28.6797 4.76939 29.2071 4.96862 29.7814 5.14441C30.9885 5.53116 31.8557 5.91791 32.4183 6.29294C33.2387 6.83204 33.6488 7.55865 33.6488 8.46107C33.6488 9.05877 33.4496 9.60959 33.0394 10.1252C32.641 10.6409 32.0901 11.0745 31.3752 11.391C30.6721 11.7074 29.84 11.8715 28.9141 11.8715C28.1875 11.8598 27.5781 11.766 27.0507 11.5902Z"
        fill="#292728"></path>
      <path
        d="M43.9269 11.6605V6.36323H36.4967V11.6605H35.1489V0.38623H36.4967V5.6835H43.9269V0.38623H45.2747V11.6605H43.9269Z"
        fill="#292728"></path>
      <path
        d="M49.6461 11.0743C48.7788 10.5469 48.0757 9.84374 47.5483 8.95304C47.0209 8.06235 46.7631 7.08963 46.7631 5.9997C46.7631 4.90978 47.0209 3.93705 47.5483 3.04636C48.0757 2.16739 48.7788 1.45249 49.6461 0.925108C50.5251 0.397725 51.4743 0.128174 52.5057 0.128174C53.5487 0.128174 54.498 0.397725 55.3653 0.925108C56.2442 1.45249 56.9357 2.15567 57.4631 3.04636C57.9905 3.93705 58.26 4.90978 58.26 5.9997C58.26 7.08963 57.9905 8.06235 57.4631 8.95304C56.9357 9.83202 56.2442 10.5469 55.3653 11.0743C54.498 11.6017 53.5487 11.8595 52.5057 11.8595C51.4861 11.8595 50.5251 11.6017 49.6461 11.0743ZM54.7558 10.5118C55.4004 10.0664 55.9044 9.44527 56.256 8.66005C56.6193 7.88656 56.7951 6.99587 56.7951 5.9997C56.7951 4.51131 56.4083 3.28075 55.6231 2.30802C54.8262 1.33529 53.7948 0.831351 52.5174 0.819631C51.6853 0.819631 50.9352 1.0423 50.2907 1.48765C49.6461 1.93299 49.1421 2.55413 48.7788 3.33935C48.4155 4.11284 48.228 5.00353 48.228 5.9997C48.228 6.99587 48.4038 7.88656 48.7788 8.66005C49.1421 9.44527 49.6344 10.0664 50.2789 10.5118C50.9118 10.9571 51.6619 11.1915 52.5174 11.1915C53.3729 11.1915 54.1113 10.9688 54.7558 10.5118Z"
        fill="#292728"></path>
      <path
        d="M59.7601 11.6605V0.38623H64.7058C65.409 0.38623 66.0536 0.526866 66.6395 0.808137C67.2255 1.10113 67.6826 1.48787 68.0342 1.9801C68.3858 2.47232 68.5498 3.0583 68.5498 3.7146C68.5498 4.3709 68.3858 4.94516 68.0342 5.4491C67.6826 5.94133 67.2255 6.3398 66.6395 6.62107C66.0653 6.90234 65.4207 7.04297 64.7058 7.04297H61.1079V11.6605H59.7601ZM66.4052 5.63662C66.8622 5.14439 67.0966 4.51154 67.0966 3.7146C67.0966 2.91767 66.8622 2.27309 66.4052 1.78086C65.9481 1.30036 65.3855 1.05425 64.7175 1.05425H61.1196V6.35152H64.7175C65.3738 6.36323 65.9481 6.12884 66.4052 5.63662Z"
        fill="#292728"></path>
    </svg>
  )
}
