import { IIcon } from "./interface"

export const WayIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53131 1.02624C4.89544 1.12902 5.10629 1.50395 5.00225 1.86367L1.34519 14.5085C1.24115 14.8682 0.86163 15.0765 0.4975 14.9738C0.133369 14.871 -0.0774777 14.4961 0.0265596 14.1363L3.68362 1.49148C3.78766 1.13176 4.16718 0.92346 4.53131 1.02624ZM11.4687 1.02624C11.8328 0.92346 12.2123 1.13176 12.3164 1.49148L15.9734 14.1363C16.0775 14.4961 15.8666 14.871 15.5025 14.9738C15.1384 15.0765 14.7588 14.8682 14.6548 14.5085L10.9977 1.86367C10.8937 1.50395 11.1046 1.12902 11.4687 1.02624ZM8 1.90338C8.3787 1.90338 8.6857 2.20666 8.6857 2.58078V4.38719C8.6857 4.76131 8.3787 5.06459 8 5.06459C7.6213 5.06459 7.3143 4.76131 7.3143 4.38719V2.58078C7.3143 2.20666 7.6213 1.90338 8 1.90338ZM8 6.41939C8.3787 6.41939 8.6857 6.72268 8.6857 7.0968V8.9032C8.6857 9.27732 8.3787 9.58061 8 9.58061C7.6213 9.58061 7.3143 9.27732 7.3143 8.9032V7.0968C7.3143 6.72268 7.6213 6.41939 8 6.41939ZM8 10.9354C8.3787 10.9354 8.6857 11.2387 8.6857 11.6128V13.4192C8.6857 13.7933 8.3787 14.0966 8 14.0966C7.6213 14.0966 7.3143 13.7933 7.3143 13.4192V11.6128C7.3143 11.2387 7.6213 10.9354 8 10.9354Z"
      />
    </svg>
  )
}
