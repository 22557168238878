import { IIcon } from "./interface"

export const FlashIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" className={className}>
      <g clipPath="url(#clip0_6771_10148)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.31978 0.0304741C7.601 0.11831 7.79265 0.379973 7.79265 0.676102V5.63382H11.0419C11.2916 5.63382 11.5207 5.77294 11.6369 5.99513C11.7531 6.21733 11.7374 6.48599 11.5959 6.69287L5.43346 15.7069C5.26663 15.9509 4.9613 16.0574 4.68008 15.9695C4.39886 15.8817 4.20721 15.62 4.20721 15.3239V10.3662H0.957915C0.708261 10.3662 0.479168 10.2271 0.362942 10.0049C0.246716 9.78267 0.262501 9.51401 0.403937 9.30713L6.5664 0.293099C6.73323 0.0490762 7.03856 -0.0573617 7.31978 0.0304741ZM2.23592 9.01408H4.87948C5.25077 9.01408 5.55175 9.31676 5.55175 9.69013V13.1472L9.76394 6.98592H7.12038C6.74909 6.98592 6.44811 6.68324 6.44811 6.30987V2.85277L2.23592 9.01408Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6771_10148">
          <rect width="12" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
