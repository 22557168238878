import { IIcon } from "./interface"

export const BurgerIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="24" height="14" viewBox="0 0 24 14" fill="#272A37" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.75C0 0.335786 0.335786 0 0.75 0H23.25C23.6642 0 24 0.335786 24 0.75C24 1.16421 23.6642 1.5 23.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75ZM0 6.75C0 6.33579 0.335786 6 0.75 6H23.25C23.6642 6 24 6.33579 24 6.75C24 7.16421 23.6642 7.5 23.25 7.5H0.75C0.335786 7.5 0 7.16421 0 6.75ZM0.75 12C0.335786 12 0 12.3358 0 12.75C0 13.1642 0.335786 13.5 0.75 13.5H23.25C23.6642 13.5 24 13.1642 24 12.75C24 12.3358 23.6642 12 23.25 12H0.75Z"
        fill="#272A37"></path>
    </svg>
  )
}
