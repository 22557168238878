import React from "react"

import { IIcon } from "./interface"

export const CookieBannerIcon: React.FC<IIcon> = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
      <g id="cookie-svgrepo-com">
        <path
          id="Vector"
          d="M29.9813 18.4438L32.0125 3.33125C30.0625 2.84375 28.1125 1.625 26 1.625C23.8875 1.625 21.9375 2.84375 19.9062 3.4125C17.875 3.9 15.6 3.81875 13.8125 4.875C12.025 5.93125 10.8875 7.9625 9.425 9.425C7.9625 10.8875 5.93125 12.025 4.875 13.8125C3.81875 15.6 3.9 17.875 3.33125 19.9062C2.84375 21.9375 1.625 23.8875 1.625 26C1.625 28.1125 2.84375 30.0625 3.4125 32.0938C3.9 34.125 3.81875 36.4 4.875 38.1875C5.93125 39.975 7.9625 41.1125 9.425 42.575C10.8875 44.0375 11.9438 46.0687 13.8125 47.125C15.6 48.1813 17.875 48.1 19.9062 48.6688C21.9375 49.1562 23.8875 50.375 26 50.375C28.1125 50.375 30.0625 49.1562 32.0938 48.5875C34.125 48.0187 36.4 48.1 38.1875 47.0438C39.975 45.9875 41.1125 43.9562 42.575 42.4937C44.0375 41.0312 46.0687 39.975 47.125 38.1063C48.1813 36.3188 48.1 34.0438 48.6688 32.0125C49.1562 30.0625 50.375 28.1125 50.375 26C50.375 23.8875 48.6688 19.4187 48.6688 19.4187L29.9813 18.4438Z"
          fill="#DDA85F"
        />
        <path
          id="Vector_2"
          d="M48.2623 18.2C47.4498 18.4437 46.3123 18.3625 45.0936 17.875C43.3873 17.225 42.3311 15.8438 42.0061 14.2188C41.1936 14.4625 39.2436 14.2188 37.9436 13.4062C35.9936 12.1875 35.5873 8.775 35.5873 8.775C33.3936 8.125 31.7686 5.525 32.0123 3.33125C30.2248 2.84375 27.9498 2.925 25.9998 2.925C24.0498 2.925 22.2623 4.0625 20.4748 4.55C18.6061 5.0375 16.4936 4.95625 14.8686 5.93125C13.2436 6.90625 12.1873 8.775 10.8873 10.075C9.50605 11.4563 7.63731 12.4312 6.74355 14.0562C5.76855 15.6812 5.8498 17.7938 5.3623 19.6625C4.8748 21.45 3.7373 23.2375 3.7373 25.1875C3.7373 27.1375 4.8748 28.925 5.3623 30.7125C5.8498 32.5812 5.76855 34.6938 6.74355 36.3188C7.71855 37.9438 9.5873 39 10.8873 40.3C12.2686 41.6812 13.2436 43.55 14.8686 44.4438C16.4936 45.4188 18.6061 45.3375 20.4748 45.825C22.2623 46.3125 24.0498 47.45 25.9998 47.45C27.9498 47.45 29.7373 46.3125 31.5248 45.825C33.3936 45.3375 35.5061 45.4188 37.1311 44.4438C38.7561 43.4688 39.8123 41.6 41.1123 40.3C42.4936 38.9187 44.3623 37.9438 45.2561 36.3188C46.2311 34.6938 46.1498 32.5812 46.6373 30.7125C47.1248 28.925 49.0748 27.4625 49.3186 25.5125C49.9686 22.3438 48.3436 18.7687 48.2623 18.2Z"
          fill="#F2CB7D"
        />
        <path id="Vector_3" d="M40.7064 8.775L39.5689 9.9125L38.5127 8.775L39.5689 7.71875L40.7064 8.775Z" fill="#DDA85F" />
        <path id="Vector_4" d="M45.3375 14.4625L44.85 15.0313L44.2812 14.4625L44.85 13.8938L45.3375 14.4625Z" fill="#DDA85F" />
        <path id="Vector_5" d="M41.2752 10.725L40.7064 11.2937L40.1377 10.725L40.7064 10.1562L41.2752 10.725Z" fill="#DDA85F" />
        <path id="Vector_6" d="M36.2376 5.7687L35.6688 6.33745L35.1001 5.7687L35.6688 5.19995L36.2376 5.7687Z" fill="#DDA85F" />
        <path id="Vector_7" d="M46.4749 16.4938L45.9062 17.0625L45.3374 16.4938L45.9062 15.925L46.4749 16.4938Z" fill="#DDA85F" />
        <path id="Vector_8" d="M46.9627 14.4625L46.3939 15.0313L45.8252 14.4625L46.3939 13.8938L46.9627 14.4625Z" fill="#DDA85F" />
        <path
          id="Vector_9"
          d="M9.58772 16.7375C8.77522 18.1187 9.99397 20.6375 11.619 21.3687C13.9752 22.3437 15.3565 22.5062 16.819 19.9875C17.3065 19.0937 17.9565 16.7375 17.7127 16.1687C17.3877 15.3562 16.0065 13.7312 15.1127 13.7312C12.594 13.8125 10.1565 15.7625 9.58772 16.7375Z"
          fill="#6D4934"
        />
        <path
          id="Vector_10"
          d="M9.99368 16.7375C9.42493 17.7125 10.8874 20.6375 12.8374 20.3937C15.5187 20.0687 15.2749 14.5437 14.1374 14.5437C12.1874 14.5437 10.3999 16.0062 9.99368 16.7375Z"
          fill="#A37F6A"
        />
        <path
          id="Vector_11"
          d="M36.7248 31.7687C37.8623 31.4437 38.6748 29.4124 38.1873 28.1937C37.456 26.3249 36.7248 25.5124 34.6123 26.0812C33.881 26.3249 32.1748 27.2187 32.0123 27.6249C31.7685 28.2749 31.606 29.8999 32.0935 30.3874C33.4748 31.7687 35.9123 32.0124 36.7248 31.7687Z"
          fill="#6D4934"
        />
        <path
          id="Vector_12"
          d="M35.5874 29.8189C36.4811 29.5751 37.8624 26.8126 36.3999 26.6501C33.8811 26.2439 31.9311 27.4626 32.1749 28.8439C32.4186 30.2251 34.9374 29.9814 35.5874 29.8189Z"
          fill="#A37F6A"
        />
        <path
          id="Vector_13"
          d="M20.2313 36.1561C19.9876 35.1811 18.2001 34.4499 17.0626 34.9374C15.4376 35.5874 14.7063 36.1561 15.2751 38.0249C15.4376 38.6749 16.2501 40.1374 16.6563 40.2186C17.2251 40.4624 18.6063 40.5436 19.0126 40.1374C20.2313 38.9999 20.3938 36.8874 20.2313 36.1561Z"
          fill="#6D4934"
        />
        <path
          id="Vector_14"
          d="M18.8499 35.425C18.6874 34.6938 15.2749 35.75 15.5999 37.05C16.2499 39.2438 16.2499 39.5688 16.8999 39.4875C18.1187 39.1625 18.9312 35.9938 18.8499 35.425Z"
          fill="#A37F6A"
        />
        <path
          id="Vector_15"
          d="M41.5189 20.7189C40.5439 20.9626 39.8127 22.7501 40.3002 23.8876C40.9502 25.5126 42.4939 25.7564 44.2002 24.8626C45.6627 24.1314 45.7439 21.5314 45.3377 21.0439C44.1189 19.8251 42.2502 20.5564 41.5189 20.7189Z"
          fill="#6D4934"
        />
        <path
          id="Vector_16"
          d="M41.1125 21.6125C40.625 22.1812 40.2187 24.4562 41.4375 24.1312C43.6312 23.4812 45.175 21.2875 44.2 20.9625C42.9812 20.5562 41.6812 20.9625 41.1125 21.6125Z"
          fill="#A37F6A"
        />
        <path id="Vector_17" d="M18.4761 13.0909L20.0848 11.4822L21.6936 13.0909L20.0848 14.6997L18.4761 13.0909Z" fill="#6D4934" />
        <path id="Vector_18" d="M11.9487 27.2049L13.5567 25.5962L15.1654 27.2041L13.5575 28.8129L11.9487 27.2049Z" fill="#DDA85F" />
        <path id="Vector_19" d="M28.1919 36.409L29.8006 34.8003L31.4094 36.409L29.8006 38.0178L28.1919 36.409Z" fill="#DDA85F" />
        <path id="Vector_20" d="M26.0308 31.9222L28.2139 29.739L30.3971 31.9222L28.2139 34.1054L26.0308 31.9222Z" fill="#DDA85F" />
        <path id="Vector_21" d="M20.0654 7.98446L22.2486 5.80127L24.4318 7.98446L22.2486 10.1676L20.0654 7.98446Z" fill="#DDA85F" />
        <path id="Vector_22" d="M33.4116 37.6568L35.0204 36.0481L36.6291 37.6568L35.0204 39.2656L33.4116 37.6568Z" fill="#6D4934" />
        <path id="Vector_23" d="M39.874 28.6122L41.4828 27.0034L43.0915 28.6122L41.4828 30.2209L39.874 28.6122Z" fill="#6D4934" />
        <path id="Vector_24" d="M33.9722 20.0175L34.7765 19.2131L35.5809 20.0175L34.7765 20.8219L33.9722 20.0175Z" fill="#6D4934" />
        <path id="Vector_25" d="M11.1523 31.0912L11.9567 30.2869L12.7611 31.0912L11.9567 31.8956L11.1523 31.0912Z" fill="#6D4934" />
      </g>
    </svg>
  )
}
