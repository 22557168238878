import { IIcon } from "./interface"

export const DevelopersV1Icon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M10.8333 6.33333H9.16667V8H10.8333V6.33333ZM10.8333 9.66667H9.16667V11.3333H10.8333V9.66667ZM14.1667 9.66667H12.5V11.3333H14.1667V9.66667ZM2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V4.66667C17.5 3.75 16.75 3 15.8333 3H4.16667C3.25 3 2.5 3.75 2.5 4.66667ZM15 16.3333H5C4.54167 16.3333 4.16667 15.9583 4.16667 15.5V5.5C4.16667 5.04167 4.54167 4.66667 5 4.66667H15C15.4583 4.66667 15.8333 5.04167 15.8333 5.5V15.5C15.8333 15.9583 15.4583 16.3333 15 16.3333ZM10.8333 13H9.16667V14.6667H10.8333V13ZM7.5 9.66667H5.83333V11.3333H7.5V9.66667Z"
        fill="#646464"
      />
    </svg>
  )
}
