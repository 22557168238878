import { IIcon } from "./interface"

export const LinkedinIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className={className}>
      <g id="Frame" clipPath="url(#clip0_5110_10392)">
        <path
          id="Vector"
          d="M8.96 15.326H5.531C5.531 15.244 5.576 6.14996 5.531 5.20596H8.96V6.63796C9.22854 6.2082 9.58006 5.83629 9.994 5.54396C10.6055 5.1472 11.3233 4.94595 12.052 4.96696C12.5737 4.94564 13.0942 5.03354 13.5799 5.22502C14.0657 5.41651 14.5062 5.70737 14.873 6.07896C15.6791 7.03966 16.0828 8.27462 16 9.52596V15.326H12.571V9.91096C12.571 8.86696 12.271 7.62296 10.833 7.62296C10.4188 7.61571 10.015 7.75289 9.691 8.01096C9.41774 8.23522 9.20552 8.52484 9.074 8.85296C8.98453 9.11662 8.94617 9.39493 8.961 9.67296V15.326H8.96ZM3.632 15.326H0.2V5.20496H3.63V15.326H3.632ZM1.918 3.82596H1.9C1.65827 3.84421 1.41538 3.81253 1.18642 3.73288C0.957465 3.65324 0.747345 3.52733 0.569133 3.363C0.39092 3.19867 0.248426 2.99943 0.150515 2.77767C0.0526038 2.5559 0.00136989 2.31637 0 2.07396C0.00206773 1.8284 0.0551821 1.58595 0.155969 1.36202C0.256757 1.13808 0.403014 0.937554 0.585451 0.773172C0.767889 0.60879 0.982519 0.484146 1.21571 0.407157C1.4489 0.330168 1.69555 0.302517 1.94 0.325959C2.18286 0.304973 2.42744 0.334493 2.65833 0.41266C2.88922 0.490827 3.10143 0.615948 3.2816 0.780147C3.46177 0.944346 3.60599 1.14407 3.70519 1.36674C3.80439 1.5894 3.85642 1.8302 3.858 2.07396C3.86107 2.30545 3.81629 2.53508 3.72646 2.74846C3.63664 2.96184 3.50371 3.15436 3.336 3.31396C2.94696 3.66095 2.43898 3.84437 1.918 3.82596Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5110_10392">
          <rect width="16" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
