import { IIcon } from "./interface"

export const TnetGeoIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="73" height="13" viewBox="0 0 73 13" fill="none" className={className}>
      <g id="Group">
        <path
          id="Vector"
          d="M24.1941 2.58472H18.6615C17.3166 2.58472 16.2315 3.66984 16.2315 5.01478V7.92626C16.2315 9.2712 17.3166 10.3563 18.6615 10.3563H20.6713C22.0162 10.3563 23.1014 9.2712 23.1014 7.92626V4.25825H24.943V9.59979C24.943 10.0659 25.3174 10.4404 25.7836 10.4404C26.2497 10.4404 26.6242 10.0659 26.6242 9.59979V5.01478C26.6242 3.66984 25.5391 2.58472 24.1941 2.58472ZM21.4278 8.67515H17.9126V4.25825H21.4278V8.67515Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_2"
          d="M49.8016 2.58472H45.0026V4.26589H50.5581V8.68279H45.0026V10.364H49.8016C51.1465 10.364 52.2316 9.27884 52.2316 7.9339V5.01478C52.2316 3.66984 51.1389 2.58472 49.8016 2.58472Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_3"
          d="M33.2192 2.58472H30.0556C28.7106 2.58472 27.6255 3.66984 27.6255 5.01478V9.60743C27.6255 10.0736 28 10.448 28.4661 10.448C28.9322 10.448 29.3067 10.0736 29.3067 9.60743V4.25825H33.8459V9.59979C33.8459 10.0659 34.2203 10.4404 34.6864 10.4404C35.1526 10.4404 35.527 10.0659 35.527 9.59979V5.01478C35.5194 3.66984 34.5642 2.58472 33.2192 2.58472Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_4"
          d="M41.9002 5.15234H38.1558V4.25826H42.6721C43.1382 4.25826 43.5126 3.88382 43.5126 3.41767C43.5126 2.95153 43.1382 2.57709 42.6721 2.57709H38.9047C37.5598 2.57709 36.4746 3.66221 36.4746 5.00715V10.3487H41.9002C43.2452 10.3487 44.3303 9.26357 44.3303 7.91863C44.3303 7.91863 44.3227 7.40664 44.3227 7.35315C44.3227 6.10755 43.1688 5.15234 41.9002 5.15234ZM42.6568 8.67516H38.1635V6.83351H42.6568V8.67516Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_5"
          d="M70.1593 2.58472H66.9956C65.6507 2.58472 64.5656 3.66984 64.5656 5.01478V9.60743C64.5656 10.0736 64.94 10.448 65.4061 10.448C65.8723 10.448 66.2467 10.0736 66.2467 9.60743V4.25825H70.7859V9.59979C70.7859 10.0659 71.1603 10.4404 71.6265 10.4404C72.0926 10.4404 72.4671 10.0659 72.4671 9.59979V5.01478C72.4594 3.66984 71.5042 2.58472 70.1593 2.58472Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_6"
          d="M61.157 2.58472H55.6244C54.2795 2.58472 53.1944 3.66984 53.1944 5.01478V7.92626C53.1944 9.2712 54.2795 10.3563 55.6244 10.3563H57.6342C58.9791 10.3563 60.0642 9.2712 60.0642 7.92626V4.25825H61.9059V9.59979C61.9059 10.0659 62.2803 10.4404 62.7465 10.4404C63.2126 10.4404 63.5871 10.0659 63.5871 9.59979V5.01478C63.5871 3.66984 62.4943 2.58472 61.157 2.58472ZM58.3831 8.67515H54.8679V4.25825H58.3831V8.67515Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_7"
          d="M11.1654 2.92112C10.8597 3.10452 10.7604 3.50953 10.9438 3.8152C12.1818 5.86317 11.8684 8.47663 10.172 10.1731C8.47553 11.8695 5.86207 12.1905 3.8141 10.9449C3.50843 10.7615 3.11106 10.8532 2.92002 11.1665C2.73662 11.4722 2.83596 11.8695 3.14163 12.0606C5.7016 13.6118 8.97224 13.2145 11.089 11.0901C13.2057 8.97334 13.6031 5.70269 12.0595 3.14273C11.8761 2.83706 11.4711 2.73772 11.1654 2.92112Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_8"
          d="M2.82817 2.82939C4.52462 1.13293 7.13808 0.81198 9.18606 2.05758C9.44587 2.21805 9.77447 2.16456 9.98079 1.95823C10.019 1.92003 10.0496 1.88182 10.0801 1.83597C10.2635 1.5303 10.1642 1.13293 9.85852 0.941889C7.29856 -0.609375 4.02791 -0.212007 1.91116 1.91238C-0.213227 4.02913 -0.610595 7.29978 0.940668 9.85975C1.12407 10.1654 1.52908 10.2648 1.83475 10.0814C2.14041 9.89795 2.23976 9.49294 2.05636 9.18728C0.810759 7.1393 1.13171 4.52584 2.82817 2.82939Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_9"
          d="M9.30094 8.17852C9.54548 8.26258 9.80529 8.1938 9.97341 8.02569C10.0422 7.95691 10.0957 7.88049 10.1262 7.78115C10.6153 6.39036 10.2562 4.82382 9.21688 3.78455C8.17761 2.74528 6.60342 2.38612 5.22028 2.87519C4.88404 2.99745 4.70828 3.36425 4.82291 3.70049C4.94518 4.03672 5.31198 4.21248 5.64821 4.09786C6.56522 3.7769 7.60449 4.00616 8.29988 4.70155C8.98763 5.3893 9.22452 6.42857 8.90357 7.35322C8.78131 7.68945 8.95707 8.05625 9.30094 8.17852Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_10"
          d="M7.71908 10.1502C7.81842 10.1196 7.90248 10.0661 7.97125 9.99737C8.13937 9.82926 8.20814 9.57708 8.13173 9.33255C8.0171 8.98867 7.6503 8.80527 7.30642 8.91989C6.38178 9.22556 5.38836 8.98867 4.70061 8.30856C4.01285 7.62081 3.77596 6.61974 4.08927 5.70274C4.2039 5.36651 4.0205 4.99206 3.67662 4.87744C3.33274 4.76281 2.96594 4.94621 2.85132 5.29009C2.38517 6.68088 2.74433 8.19393 3.77596 9.22556C4.81523 10.2572 6.32829 10.6087 7.71908 10.1502Z"
          fill="#3C74FF"
        />
        <path
          id="Vector_11"
          d="M5.68592 5.68739C5.23506 6.13825 5.23506 6.86421 5.68592 7.31507C6.13678 7.76593 6.86274 7.76593 7.3136 7.31507C7.76446 6.86421 7.76446 6.13825 7.3136 5.68739C6.86274 5.23653 6.13678 5.23653 5.68592 5.68739Z"
          fill="#3C74FF"
        />
      </g>
    </svg>
  )
}
