import { IIcon } from "./interface"

export const PaginationFirstIcon: React.FC<IIcon> = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M18.209 8.11L14.319 12L18.209 15.89C18.599 16.28 18.599 16.91 18.209 17.3C17.819 17.69 17.189 17.69 16.799 17.3L12.209 12.71C11.819 12.32 11.819 11.69 12.209 11.3L16.799 6.7C17.189 6.31 17.819 6.31 18.209 6.7C18.589 7.09 18.589 7.73 18.209 8.11ZM7.49897 6C6.94897 6 6.49897 6.45 6.49897 7V17C6.49897 17.55 6.94897 18 7.49897 18C8.04897 18 8.49897 17.55 8.49897 17V7C8.49897 6.45 8.04897 6 7.49897 6Z"
        fill="#222222"
      />
    </svg>
  )
}
