import { IIcon } from "./interface"

export const CardPurple: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="126" height="120" viewBox="0 0 126 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7414_75531)">
        <path
          opacity="0.2"
          d="M118 93.5C118 99.02 113.522 103.5 108 103.5H23C17.4775 103.5 13 99.02 13 93.5V38.5C13 32.975 17.4775 28.5 23 28.5H108C113.522 28.5 118 32.975 118 38.5V93.5Z"
          fill="#B477C7"
        />
        <path
          d="M47.5 58.5C47.5 59.8825 46.3825 61 45 61H32.5C31.1175 61 30 59.8825 30 58.5V48.5C30 47.1175 31.1175 46 32.5 46H45C46.3825 46 47.5 47.1175 47.5 48.5V58.5ZM45 73.5C45 72.1193 43.8807 71 42.5 71H32.5C31.1193 71 30 72.1193 30 73.5C30 74.8807 31.1193 76 32.5 76H42.5C43.8807 76 45 74.8807 45 73.5ZM65 86C65 84.6193 63.8807 83.5 62.5 83.5H32.5C31.1193 83.5 30 84.6193 30 86C30 87.3807 31.1193 88.5 32.5 88.5H62.5C63.8807 88.5 65 87.3807 65 86ZM65 73.5C65 72.1193 63.8807 71 62.5 71H52.5C51.1193 71 50 72.1193 50 73.5C50 74.8807 51.1193 76 52.5 76H62.5C63.8807 76 65 74.8807 65 73.5ZM85 73.5C85 72.1193 83.8807 71 82.5 71H72.5C71.1193 71 70 72.1193 70 73.5C70 74.8807 71.1193 76 72.5 76H82.5C83.8807 76 85 74.8807 85 73.5ZM105 73.5C105 72.1193 103.881 71 102.5 71H92.5C91.1192 71 90 72.1193 90 73.5C90 74.8807 91.1193 76 92.5 76H102.5C103.881 76 105 74.8807 105 73.5Z"
          fill="#FEB399"
        />
        <path
          d="M58 54C58 52.3431 59.3431 51 61 51H99C100.657 51 102 52.3431 102 54C102 55.6569 100.657 57 99 57H61C59.3431 57 58 55.6569 58 54Z"
          fill="#9FA8DA"
        />
        <path
          d="M112 87.5C112 93.02 107.522 97.4999 102 97.4999H17C11.4775 97.4999 7 93.02 7 87.5V32.5C7 26.975 11.4775 22.5 17 22.5H102C107.522 22.5 112 26.975 112 32.5V87.5Z"
          fill="#A155B9"
        />
        <path
          d="M39.5 52.5C39.5 53.8825 38.3825 55 37 55H24.5C23.1175 55 22 53.8825 22 52.5V42.5C22 41.1175 23.1175 40 24.5 40H37C38.3825 40 39.5 41.1175 39.5 42.5V52.5ZM37 67.5C37 66.1193 35.8807 65 34.5 65H24.5C23.1193 65 22 66.1193 22 67.5C22 68.8807 23.1193 70 24.5 70H34.5C35.8807 70 37 68.8807 37 67.5ZM57 80C57 78.6193 55.8807 77.5 54.5 77.5H24.5C23.1193 77.5 22 78.6193 22 80C22 81.3807 23.1193 82.5 24.5 82.5H54.5C55.8807 82.5 57 81.3807 57 80ZM57 67.5C57 66.1193 55.8807 65 54.5 65H44.5C43.1193 65 42 66.1193 42 67.5C42 68.8807 43.1193 70 44.5 70H54.5C55.8807 70 57 68.8807 57 67.5ZM77 67.5C77 66.1193 75.8807 65 74.5 65H64.5C63.1193 65 62 66.1193 62 67.5C62 68.8807 63.1193 70 64.5 70H74.5C75.8807 70 77 68.8807 77 67.5ZM96.9999 67.5C96.9999 66.1193 95.8807 65 94.4999 65H84.5C83.1192 65 82 66.1193 82 67.5C82 68.8807 83.1193 70 84.5 70H94.4999C95.8807 70 96.9999 68.8807 96.9999 67.5Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M50 48C50 46.3431 51.3431 45 53 45H91C92.6569 45 94 46.3431 94 48C94 49.6569 92.6569 51 91 51H53C51.3431 51 50 49.6569 50 48Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7414_75531">
          <rect width="126" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
