import { IIcon } from "./interface"

export const LivoIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="24" height="31" viewBox="0 0 24 31" fill="#1552F5" className={className}>
      <g clipPath="url(#clip0_4807_48857)">
        <path
          d="M2.12821 10.7533C2.09258 10.7827 2.06388 10.8204 2.04437 10.8635C2.02485 10.9066 2.01505 10.9539 2.01574 11.0016V28.0069C2.01574 28.0784 2.03766 28.1479 2.07822 28.2051C2.10152 28.2365 2.13061 28.2626 2.16372 28.2819C2.19683 28.3011 2.23329 28.3131 2.27088 28.317C2.30848 28.321 2.34644 28.3169 2.38249 28.3049C2.41853 28.2929 2.4519 28.2734 2.4806 28.2474L6.18958 24.9156C6.94825 24.2343 7.91095 23.8598 8.90633 23.8587H21.7078C21.7459 23.858 21.7834 23.8494 21.8184 23.8333C21.8533 23.8173 21.8849 23.7941 21.9113 23.7652C21.9378 23.7362 21.9586 23.702 21.9726 23.6646C21.9865 23.6271 21.9934 23.5871 21.9927 23.5469V11.0096C21.9929 10.9616 21.9826 10.9143 21.9627 10.8712C21.9428 10.8281 21.9137 10.7905 21.8777 10.7612L12.1879 2.74999C12.1389 2.70884 12.0781 2.68646 12.0155 2.68646C11.9528 2.68646 11.8921 2.70884 11.843 2.74999L2.12821 10.7533ZM0.0162892 11.0016C0.0146397 10.6179 0.0957121 10.2388 0.253345 9.89318C0.410978 9.54753 0.641032 9.24438 0.926041 9.00676L10.6334 1.00349C11.0251 0.677342 11.5093 0.5 12.008 0.5C12.5066 0.5 12.9908 0.677342 13.3826 1.00349L23.0924 9.00676C23.3771 9.24451 23.6068 9.54774 23.764 9.89341C23.9212 10.2391 24.0018 10.6181 23.9996 11.0016V23.5389C24.0103 24.1902 23.776 24.8193 23.3481 25.288C22.9202 25.7567 22.3338 26.0267 21.7178 26.0385H8.91632C8.38535 26.0396 7.87221 26.241 7.46923 26.6065L3.75026 29.9437C3.47327 30.1935 3.14308 30.3685 2.78803 30.4536C2.37491 30.5397 1.94743 30.5055 1.55125 30.3547C1.15507 30.204 0.805077 29.9423 0.538651 29.5975C0.201166 29.1464 0.0187361 28.5874 0.0212927 28.0122C-0.0111983 18.6852 -0.00120595 11.8894 0.0162892 11.0016Z"
          fill="#1552F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_4807_48857">
          <rect width="24" height="30" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
