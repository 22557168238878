import { IIcon } from "./interface"

export const TimeleftStaticIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" className={className}>
      <g clipPath="url(#clip0_7207_40886)">
        <path
          d="M9.5 19C8.32417 19 7.21917 18.7767 6.185 18.3302C5.15083 17.8837 4.25125 17.2782 3.48625 16.5137C2.72125 15.7487 2.11577 14.8492 1.6698 13.815C1.22383 12.7808 1.00057 11.6758 1 10.5C1 9.32417 1.22327 8.21917 1.6698 7.185C2.11633 6.15083 2.72182 5.25125 3.48625 4.48625C4.25125 3.72125 5.15083 3.11577 6.185 2.6698C7.21917 2.22383 8.32417 2.00057 9.5 2C10.6758 2 11.7808 2.22327 12.815 2.6698C13.8492 3.11633 14.7487 3.72182 15.5137 4.48625C16.2787 5.25125 16.8845 6.15083 17.331 7.185C17.7776 8.21917 18.0006 9.32417 18 10.5C18 11.6758 17.7767 12.7808 17.3302 13.815C16.8837 14.8492 16.2782 15.7487 15.5137 16.5137C14.7487 17.2787 13.8492 17.8845 12.815 18.331C11.7808 18.7776 10.6758 19.0006 9.5 19ZM4.67625 5.67625L9.5 10.5V3.7C8.59333 3.7 7.72208 3.87 6.88625 4.21C6.05042 4.55 5.31375 5.03875 4.67625 5.67625Z"
          fill="#646464"
        />
      </g>
      <defs>
        <clipPath id="clip0_7207_40886">
          <rect width="18" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
