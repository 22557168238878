import { IIcon } from "./interface"

export const CameraIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M11.7665 4.66667L13.2915 6.33333H16.6665V16.3333H3.33317V6.33333H6.70817L8.23317 4.66667H11.7665ZM12.4998 3H7.49984L5.97484 4.66667H3.33317C2.4165 4.66667 1.6665 5.41667 1.6665 6.33333V16.3333C1.6665 17.25 2.4165 18 3.33317 18H16.6665C17.5832 18 18.3332 17.25 18.3332 16.3333V6.33333C18.3332 5.41667 17.5832 4.66667 16.6665 4.66667H14.0248L12.4998 3ZM9.99984 8.83333C11.3748 8.83333 12.4998 9.95833 12.4998 11.3333C12.4998 12.7083 11.3748 13.8333 9.99984 13.8333C8.62484 13.8333 7.49984 12.7083 7.49984 11.3333C7.49984 9.95833 8.62484 8.83333 9.99984 8.83333ZM9.99984 7.16667C7.69984 7.16667 5.83317 9.03333 5.83317 11.3333C5.83317 13.6333 7.69984 15.5 9.99984 15.5C12.2998 15.5 14.1665 13.6333 14.1665 11.3333C14.1665 9.03333 12.2998 7.16667 9.99984 7.16667Z"
        fill="#646464"
      />
    </svg>
  )
}
