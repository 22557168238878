import { IIcon } from "./interface"

export const MyPartsIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="68" height="20" viewBox="0 0 68 20" fill="none" className={className}>
      <path
        d="M58.4283 0.499268H9.70549C4.42267 0.499268 0.136047 4.63758 0.136047 9.73762V10.2622C0.136047 15.3622 4.42267 19.5005 9.70549 19.5005H58.4283C63.7111 19.5005 67.9977 15.3622 67.9977 10.2622V9.73762C67.9977 4.63758 63.7111 0.499268 58.4283 0.499268Z"
        fill="#009DB9"></path>
      <path
        d="M49.5777 10.2036C49.5777 14.1379 46.2636 17.3436 42.166 17.3436H10.0785C6.01111 17.3436 2.66675 14.1379 2.66675 10.2036V9.79556C2.66675 5.86125 5.98098 2.65552 10.0785 2.65552H42.166C46.2334 2.65552 49.5777 5.86125 49.5777 9.79556V10.2036Z"
        fill="white"></path>
      <path
        d="M40.1776 12.1563C40.2378 12.2729 40.2077 12.4186 40.0571 12.4769C39.6955 12.6518 39.2134 12.7683 38.8218 12.7683C37.6467 12.7683 37.0442 12.098 37.0442 10.874V8.4843H36.5621C36.4114 8.4843 36.291 8.36774 36.291 8.22202V7.63916C36.291 7.49345 36.4114 7.37687 36.5621 7.37687H37.0442V6.47344C37.0442 6.35687 37.1647 6.24029 37.2852 6.21115L38.3698 6.09458C38.5205 6.06544 38.641 6.18201 38.641 6.32772V7.34773H39.6052C39.7558 7.34773 39.8763 7.49345 39.8462 7.63916L39.7558 8.22202C39.7257 8.33859 39.6353 8.42603 39.4846 8.42603H38.641V10.8157C38.641 11.3112 38.8218 11.486 39.1532 11.486C39.2737 11.486 39.3942 11.4569 39.5148 11.3986C39.6353 11.3695 39.7859 11.3986 39.8462 11.5152L40.1776 12.1563Z"
        fill="#414042"></path>
      <path
        d="M14.5378 8.80487V12.3603C14.5378 12.506 14.4173 12.6226 14.2666 12.6226H13.182C13.0314 12.6226 12.9108 12.506 12.9108 12.3603V9.06715C12.9108 8.51343 12.7301 8.36773 12.4589 8.36773C12.1275 8.36773 11.8865 8.63001 11.6454 9.03801V12.3312C11.6454 12.4769 11.5249 12.5935 11.3743 12.5935H10.2595C10.139 12.5935 10.0185 12.4769 10.0185 12.3312V9.03801C10.0185 8.4843 9.80756 8.33857 9.5364 8.33857C9.20498 8.33857 8.96396 8.60087 8.72292 9.00888V12.302C8.72292 12.4478 8.60239 12.5643 8.48188 12.5643H7.36712C7.2466 12.5643 7.09595 12.4478 7.09595 12.302V7.61C7.09595 7.46428 7.21647 7.34772 7.36712 7.34772H8.30111C8.42163 7.34772 8.51199 7.43515 8.54212 7.55173L8.63252 7.95972C9.05433 7.43514 9.53638 7.17285 10.1992 7.17285C10.8018 7.17285 11.2538 7.4643 11.4647 7.98887C11.8865 7.43515 12.3986 7.17285 13.0615 7.17285C13.9654 7.23114 14.5378 7.81401 14.5378 8.80487Z"
        fill="#414042"></path>
      <path
        d="M18.7259 12.6226C18.3041 13.8758 17.4605 14.6043 15.954 14.75C15.8335 14.75 15.6829 14.6918 15.6829 14.5461L15.5925 13.934C15.5623 13.8175 15.6527 13.6718 15.7732 13.6718C16.4963 13.5552 16.888 13.322 17.129 12.8849C16.9483 12.8558 16.7374 12.5643 16.6771 12.3603L15.2008 7.72657C15.1405 7.58086 15.261 7.40601 15.4418 7.40601H16.5867C16.7072 7.40601 16.7976 7.49342 16.8579 7.60999L17.7618 11.6317L18.7861 7.60999C18.8163 7.49342 18.9067 7.43514 19.0272 7.43514H20.1419C20.3227 7.43514 20.4432 7.60999 20.383 7.75571L18.7259 12.6226Z"
        fill="#414042"></path>
      <path
        d="M31.6811 11.9231L31.4702 12.5643C31.4401 12.6808 31.3196 12.7391 31.199 12.7391C30.6869 12.6517 30.3253 12.4769 30.1144 12.0106C29.7227 12.5934 29.1503 12.7974 28.4874 12.7974C27.4329 12.7974 26.7399 12.1271 26.7399 11.1654C26.7399 10.0288 27.6137 9.41683 29.2708 9.41683H29.8131V9.18369C29.8131 8.57168 29.5721 8.36769 28.9092 8.36769C28.608 8.36769 28.1861 8.42598 27.7342 8.57169C27.6137 8.60083 27.463 8.54255 27.4028 8.42598L27.222 7.87225C27.1919 7.75568 27.2521 7.60997 27.3726 7.55168C27.9752 7.37683 28.6381 7.26025 29.1503 7.26025C30.717 7.26025 31.3798 7.87225 31.3798 9.09626V11.1071C31.3798 11.3986 31.4401 11.5734 31.5606 11.6608C31.6811 11.7191 31.7112 11.8357 31.6811 11.9231ZM29.8131 11.1654V10.2328H29.4214C28.6983 10.2328 28.3368 10.4951 28.3368 10.9906C28.3368 11.3986 28.5778 11.6608 28.9695 11.6608C29.331 11.6608 29.6022 11.4568 29.8131 11.1654Z"
        fill="#414042"></path>
      <path
        d="M43.3111 9.38769C42.3469 9.1254 42.1963 9.00884 42.1963 8.71741C42.1963 8.48426 42.4072 8.33853 42.829 8.33853C43.1905 8.33853 43.522 8.42598 43.8534 8.57169C43.8835 8.60083 43.9136 8.60082 43.9438 8.62996C44.0643 8.68825 44.215 8.65911 44.2752 8.54254L44.6066 8.0471C44.6669 7.93053 44.6367 7.78482 44.5463 7.72654C44.5162 7.69739 44.4259 7.66825 44.4259 7.66825C43.9739 7.40596 43.4015 7.26025 42.7688 7.26025C41.4129 7.26025 40.5693 7.95968 40.5693 8.83397C40.5693 9.64997 41.0815 10.1746 42.1662 10.466C43.1303 10.7283 43.3111 10.8448 43.3111 11.1946C43.3111 11.5151 43.0098 11.69 42.5277 11.69C42.1059 11.69 41.7142 11.5734 41.3828 11.3694C41.3527 11.3403 41.2924 11.3111 41.2623 11.282C41.1719 11.2237 41.0212 11.2528 40.9309 11.3694L40.5392 11.894C40.4488 12.0106 40.4789 12.1563 40.5693 12.2437C40.5994 12.2728 40.6296 12.302 40.6597 12.3311C41.1719 12.6517 41.8046 12.8557 42.5578 12.8557C43.8835 12.8557 44.938 12.2146 44.938 11.1363C44.9682 10.1163 44.3957 9.67912 43.3111 9.38769Z"
        fill="#414042"></path>
      <path
        d="M35.6584 7.26025H35.5981C35.5379 7.26025 35.4475 7.26025 35.3571 7.26025C34.7545 7.26025 34.2122 7.6974 33.9711 8.42598L33.8808 7.78483V7.72654V7.66825C33.8808 7.52253 33.7603 7.40597 33.6096 7.40597H33.3083H33.0673H32.7057C32.5551 7.40597 32.4346 7.52253 32.4346 7.66825V7.78483V12.2437V12.302V12.3603C32.4346 12.506 32.5551 12.6226 32.7057 12.6226H33.7904C33.941 12.6226 34.0615 12.506 34.0615 12.3603V12.2437V9.99969C34.2423 9.21283 34.5135 8.77568 35.1763 8.77568C35.2366 8.77568 35.327 8.77569 35.3872 8.80483H35.4174H35.4475C35.568 8.80483 35.6885 8.7174 35.7186 8.60083L35.8994 7.58082C35.8994 7.40596 35.809 7.2894 35.6584 7.26025Z"
        fill="#414042"></path>
      <path
        d="M24.149 7.23145C23.6067 7.23145 23.0342 7.43546 22.6124 7.98918L22.5823 7.78517V7.72688V7.66859C22.5823 7.52288 22.4618 7.40631 22.3111 7.40631H22.0099H21.7688H21.4073C21.2566 7.40631 21.1361 7.52288 21.1361 7.66859V7.81431V13.1183V14.4298V14.4006V14.4881C21.1361 14.6338 21.2566 14.7504 21.4073 14.7212L22.4919 14.6046C22.6124 14.5755 22.733 14.4881 22.733 14.3424V14.2841V14.3132V14.2841V12.2149C23.0644 12.5646 23.5163 12.7395 24.0285 12.7395C25.3542 12.7395 26.1676 11.5738 26.1676 9.9709C26.1375 8.30975 25.535 7.23145 24.149 7.23145ZM23.5163 11.6321C23.1849 11.6321 22.9438 11.4863 22.7028 11.1658V8.98003C22.9438 8.60117 23.2452 8.36803 23.6368 8.36803C24.1792 8.36803 24.5106 8.71774 24.5106 10C24.4804 11.1658 24.1189 11.6321 23.5163 11.6321Z"
        fill="#414042"></path>
      <path
        d="M53.5851 11.3988C53.5851 11.8359 53.1935 12.2148 52.7415 12.2148C52.2595 12.2148 51.8979 11.8359 51.8979 11.3988C51.8979 10.9616 52.2595 10.5828 52.7415 10.5828C53.1935 10.5828 53.5851 10.9616 53.5851 11.3988Z"
        fill="white"></path>
      <path
        d="M58.4961 7.66822C58.1948 7.75565 57.8333 7.7848 57.3211 7.7848C57.9538 8.04708 58.2551 8.3968 58.2551 9.00881C58.2551 9.94139 57.4717 10.5825 56.2063 10.5825C55.9954 10.5825 55.8146 10.5825 55.6339 10.5242C55.5134 10.5825 55.4531 10.6991 55.4531 10.7865C55.4531 10.9322 55.5133 11.078 55.9954 11.078H56.7486C57.8935 11.078 58.6167 11.6608 58.6167 12.4477C58.6167 13.4385 57.7128 14.0214 56.0256 14.0214C54.3685 14.0214 53.796 13.5551 53.7056 12.7683C53.6755 12.6225 53.8261 12.506 53.9768 12.506H54.7902C54.9108 12.506 55.0012 12.5643 55.0313 12.6808C55.0915 12.9723 55.3627 13.0888 56.0858 13.0888C56.9595 13.0888 57.1403 12.8557 57.1403 12.5642C57.1403 12.2728 56.9595 12.098 56.4473 12.098H55.7243C54.6396 12.098 54.2178 11.6608 54.2178 11.1654C54.2178 10.8157 54.4588 10.466 54.7902 10.262C54.1575 9.94138 53.9165 9.53338 53.9165 8.89223C53.9165 7.81394 54.7903 7.14365 56.0858 7.14365C57.0198 7.17279 57.6224 6.9688 58.1346 6.67736C58.2551 6.61908 58.4359 6.67736 58.4961 6.82308L58.6769 7.34765C58.7372 7.49337 58.6468 7.63907 58.4961 7.66822ZM55.3928 8.89223C55.3928 9.4168 55.6941 9.70823 56.146 9.70823C56.6281 9.70823 56.8692 9.4168 56.8692 8.89223C56.8692 8.36765 56.6281 8.10536 56.146 8.10536C55.6941 8.07622 55.3928 8.3968 55.3928 8.89223Z"
        fill="white"></path>
      <path
        d="M63.4373 10.1163H60.3943C60.4847 10.9615 60.9065 11.1946 61.509 11.1946C61.8405 11.1946 62.1417 11.1072 62.4732 10.9032C62.5937 10.8157 62.7443 10.874 62.8046 10.9614L63.1059 11.3695C63.1963 11.486 63.1661 11.6317 63.0456 11.7192C62.5937 12.0106 62.0212 12.2437 61.3283 12.2437C59.7013 12.2437 58.8577 11.2237 58.8577 9.73745C58.8577 8.30944 59.6712 7.17285 61.1475 7.17285C62.5636 7.17285 63.4072 8.07629 63.4072 9.62087C63.4674 9.73745 63.4674 9.97059 63.4373 10.1163ZM62.0212 9.18373C61.9911 8.54258 61.8103 8.0763 61.2078 8.0763C60.7257 8.0763 60.4244 8.36773 60.3641 9.24202H61.9911V9.18373H62.0212Z"
        fill="white"></path>
    </svg>
  )
}
