import { IIcon } from "./interface"

export const ChevronLeftIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg viewBox="0 0 7 11" fill="none" className={className}>
      <path
        d="M0.310934 0.287919C0.725513 -0.0959732 1.39522 -0.0959732 1.80979 0.287919L6.68907 4.80604C7.10364 5.18993 7.10364 5.81007 6.68907 6.19396L1.80979 10.7121C1.39522 11.096 0.725513 11.096 0.310934 10.7121C-0.103645 10.3282 -0.103645 9.70805 0.310934 9.32416L4.43546 5.49508L0.310934 1.67584C-0.103645 1.29195 -0.0930142 0.661969 0.310934 0.287919Z"
        fill="inherit"
      />
    </svg>
  )
}
