import { IIcon } from "./interface"

export const ArrowRightIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="#222222" className={className}>
      <g clipPath="url(#clip0_4784_49846)">
        <path
          d="M2.29261 0.292612C2.65261 -0.0673881 3.22061 -0.0953881 3.61261 0.209612L3.70661 0.292612L7.70661 4.29261C8.06661 4.65261 8.09461 5.22061 7.78961 5.61261L7.70661 5.70661L3.70661 9.70661C3.31661 10.0966 2.68261 10.0966 2.29261 9.70661C1.93261 9.34661 1.90461 8.77861 2.20961 8.38661L2.29261 8.29261L5.58461 4.99961L2.29261 1.70661C1.93261 1.34661 1.90461 0.778612 2.20961 0.386612L2.29261 0.292612Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_4784_49846">
          <rect width="10" height="10" fill="white" transform="matrix(0 -1 1 0 0 10)" />
        </clipPath>
      </defs>
    </svg>
  )
}
