import { IIcon } from "./interface"

export const LeftBigArrow: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" className={className}>
      <path
        d="M3.23301 4.66665H10.6663C10.8552 4.66665 11.0136 4.60277 11.1413 4.47499C11.2691 4.34721 11.333 4.18888 11.333 3.99999C11.333 3.8111 11.2691 3.65277 11.1413 3.52499C11.0136 3.39721 10.8552 3.33332 10.6663 3.33332H3.23301L5.13301 1.43332C5.26634 1.29999 5.33023 1.14443 5.32467 0.966654C5.31912 0.788876 5.25523 0.633321 5.13301 0.499987C4.99967 0.366654 4.84134 0.29721 4.65801 0.291654C4.47467 0.286099 4.31634 0.349987 4.18301 0.483321L1.13301 3.53332C1.06634 3.59999 1.01912 3.67221 0.991341 3.74999C0.963563 3.82777 0.949675 3.9111 0.949675 3.99999C0.949675 4.08888 0.963563 4.17221 0.991341 4.24999C1.01912 4.32777 1.06634 4.39999 1.13301 4.46665L4.18301 7.51665C4.31634 7.64999 4.47467 7.71388 4.65801 7.70832C4.84134 7.70277 4.99967 7.63332 5.13301 7.49999C5.25523 7.36665 5.31912 7.2111 5.32467 7.03332C5.33023 6.85554 5.26634 6.69999 5.13301 6.56665L3.23301 4.66665Z"
        fill="#4E4E4E"
      />
    </svg>
  )
}
