import { IIcon } from "./interface"

export const AgenciesIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M7.49984 11.9584C5.54984 11.9584 1.6665 12.9334 1.6665 14.875V16.3334H13.3332V14.875C13.3332 12.9334 9.44984 11.9584 7.49984 11.9584ZM3.6165 14.6667C4.3165 14.1834 6.00817 13.625 7.49984 13.625C8.9915 13.625 10.6832 14.1834 11.3832 14.6667H3.6165ZM7.49984 10.5C9.10817 10.5 10.4165 9.19169 10.4165 7.58335C10.4165 5.97502 9.10817 4.66669 7.49984 4.66669C5.8915 4.66669 4.58317 5.97502 4.58317 7.58335C4.58317 9.19169 5.8915 10.5 7.49984 10.5ZM7.49984 6.33335C8.1915 6.33335 8.74984 6.89169 8.74984 7.58335C8.74984 8.27502 8.1915 8.83335 7.49984 8.83335C6.80817 8.83335 6.24984 8.27502 6.24984 7.58335C6.24984 6.89169 6.80817 6.33335 7.49984 6.33335ZM13.3665 12.0084C14.3332 12.7084 14.9998 13.6417 14.9998 14.875V16.3334H18.3332V14.875C18.3332 13.1917 15.4165 12.2334 13.3665 12.0084ZM12.4998 10.5C14.1082 10.5 15.4165 9.19169 15.4165 7.58335C15.4165 5.97502 14.1082 4.66669 12.4998 4.66669C12.0498 4.66669 11.6332 4.77502 11.2498 4.95835C11.7748 5.70002 12.0832 6.60835 12.0832 7.58335C12.0832 8.55835 11.7748 9.46669 11.2498 10.2084C11.6332 10.3917 12.0498 10.5 12.4998 10.5Z"
        fill="#646464"
      />
    </svg>
  )
}
