import { IIcon } from "./interface"

export const TablerCameraIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg viewBox="0 0 64 64" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2289 9.5621C21.2291 8.56191 22.5857 8 24.0002 8H40.0002C41.4147 8 42.7712 8.56191 43.7714 9.5621C44.7716 10.5623 45.3335 11.9188 45.3335 13.3333C45.3335 14.0406 45.6144 14.7189 46.1145 15.219C46.6146 15.719 47.2929 16 48.0002 16H50.6668C52.7886 16 54.8234 16.8429 56.3237 18.3431C57.824 19.8434 58.6668 21.8783 58.6668 24V48C58.6668 50.1217 57.824 52.1566 56.3237 53.6569C54.8234 55.1571 52.7886 56 50.6668 56H13.3335C11.2118 56 9.17693 55.1571 7.67664 53.6569C6.17635 52.1566 5.3335 50.1217 5.3335 48V24C5.3335 21.8783 6.17635 19.8434 7.67664 18.3431C9.17693 16.8429 11.2118 16 13.3335 16H16.0002C16.7074 16 17.3857 15.719 17.8858 15.219C18.3859 14.7189 18.6668 14.0406 18.6668 13.3333C18.6668 11.9188 19.2287 10.5623 20.2289 9.5621ZM40.0002 13.3333L24.0002 13.3333C24.0002 15.4551 23.1573 17.4899 21.657 18.9902C20.1567 20.4905 18.1219 21.3333 16.0002 21.3333H13.3335C12.6263 21.3333 11.948 21.6143 11.4479 22.1144C10.9478 22.6145 10.6668 23.2928 10.6668 24V48C10.6668 48.7072 10.9478 49.3855 11.4479 49.8856C11.948 50.3857 12.6263 50.6667 13.3335 50.6667H50.6668C51.3741 50.6667 52.0523 50.3857 52.5524 49.8856C53.0525 49.3855 53.3335 48.7072 53.3335 48V24C53.3335 23.2928 53.0525 22.6145 52.5524 22.1144C52.0523 21.6143 51.3741 21.3333 50.6668 21.3333H48.0002C45.8784 21.3333 43.8436 20.4905 42.3433 18.9902C40.843 17.4899 40.0002 15.4551 40.0002 13.3333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4577 27.1242C26.4581 25.1238 29.1712 24 32.0002 24C34.8291 24 37.5422 25.1238 39.5426 27.1242C41.543 29.1246 42.6668 31.8377 42.6668 34.6667C42.6668 37.4956 41.543 40.2087 39.5426 42.2091C37.5422 44.2095 34.8291 45.3333 32.0002 45.3333C29.1712 45.3333 26.4581 44.2095 24.4577 42.2091C22.4573 40.2087 21.3335 37.4956 21.3335 34.6667C21.3335 31.8377 22.4573 29.1246 24.4577 27.1242ZM32.0002 29.3333C30.5857 29.3333 29.2291 29.8952 28.2289 30.8954C27.2287 31.8956 26.6668 33.2522 26.6668 34.6667C26.6668 36.0812 27.2287 37.4377 28.2289 38.4379C29.2291 39.4381 30.5857 40 32.0002 40C33.4147 40 34.7712 39.4381 35.7714 38.4379C36.7716 37.4377 37.3335 36.0812 37.3335 34.6667C37.3335 33.2522 36.7716 31.8956 35.7714 30.8954C34.7712 29.8952 33.4147 29.3333 32.0002 29.3333Z"
        fill="white"
      />
    </svg>
  )
}
