import { IIcon } from "./interface"

export const AddCircleIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" className={className}>
      <path
        d="M10.4998 18.3333C15.0832 18.3333 18.8332 14.5833 18.8332 9.99996C18.8332 5.41663 15.0832 1.66663 10.4998 1.66663C5.9165 1.66663 2.1665 5.41663 2.1665 9.99996C2.1665 14.5833 5.9165 18.3333 10.4998 18.3333Z"
        stroke="#26B571"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.1665 10H13.8332" stroke="#26B571" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 13.3333V6.66663" stroke="#26B571" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
