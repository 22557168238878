import { IIcon } from "./interface"

export const CardViewIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className={className}>
      <rect width="16" height="12" rx="2" />
      <rect y="13" width="16" height="3" rx="1.5" />
    </svg>
  )
}
