import { IIcon } from "./interface"

export const SeenIcon: React.FC<IIcon> = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <rect width="14" height="14" rx="7" fill="#DDDDDD" />
      <path
        d="M5.66683 8.62501L3.91683 6.87501L3.3335 7.45834L5.66683 9.79168L10.6668 4.79168L10.0835 4.20834L5.66683 8.62501Z"
        fill="white"
      />
    </svg>
  )
}
