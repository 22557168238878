import { IIcon } from "./interface"

export const EditIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#646464" className={className}>
      <g id="edit">
        <path
          id="Vector"
          d="M11.7157 7.51667L12.4824 8.28333L4.93236 15.8333H4.16569V15.0667L11.7157 7.51667ZM14.7157 2.5C14.5074 2.5 14.2907 2.58333 14.1324 2.74167L12.6074 4.26667L15.7324 7.39167L17.2574 5.86667C17.5824 5.54167 17.5824 5.01667 17.2574 4.69167L15.3074 2.74167C15.1407 2.575 14.9324 2.5 14.7157 2.5ZM11.7157 5.15833L2.49902 14.375V17.5H5.62402L14.8407 8.28333L11.7157 5.15833Z"
        />
      </g>
    </svg>
  )
}
