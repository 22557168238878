import { IIcon } from "./interface"

export const VendooIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="82" height="18" viewBox="0 0 82 18" fill="none" className={className}>
      <path
        d="M20.5484 12.2983C20.4043 12.2983 20.2811 12.2432 20.1726 12.1379C20.0657 12.0294 20.0122 11.9046 20.0122 11.7621V11.2437H24.174V7.55977H20.0122V7.02841C20.0122 6.88585 20.0641 6.76111 20.1726 6.65419C20.2811 6.54727 20.4043 6.49381 20.5484 6.49381H26.766V2.69653H20.5339C19.0775 2.69653 18.1298 3.08533 17.3425 3.86293C16.6086 4.60651 16.2198 5.48617 16.1744 6.50677V12.2983C16.2198 13.3173 16.6086 14.1969 17.3425 14.9389C18.1298 15.7165 19.0775 16.1037 20.1856 16.1037H26.7676V12.2967H20.5484V12.2983Z"
        fill="#7CB339"></path>
      <path
        d="M45.4608 2.69482H41.6522V16.1052H45.4608C47.3093 16.1052 48.8904 15.4507 50.201 14.1417C51.5099 12.8328 52.166 11.2517 52.166 9.40162C52.166 7.54996 51.5099 5.97208 50.201 4.65988C48.8888 3.3493 47.3093 2.69482 45.4608 2.69482ZM47.4972 11.4461C46.935 12.0131 46.2563 12.2966 45.4608 12.2966V6.4921C46.2563 6.4921 46.935 6.7756 47.4972 7.3426C48.0609 7.91122 48.3428 8.5981 48.3428 9.40162C48.3428 10.197 48.0609 10.8774 47.4972 11.4461Z"
        fill="#1D41EF"></path>
      <path
        d="M59.9988 2.69482C58.1471 2.69482 56.5676 3.35254 55.257 4.66474C53.9464 5.98018 53.2936 7.55644 53.2936 9.4C53.2936 11.25 53.9464 12.8295 55.257 14.1401C56.5676 15.4491 58.1471 16.1036 59.9988 16.1036C61.8488 16.1036 63.4315 15.4491 64.7454 14.1401C66.0592 12.8295 66.7169 11.25 66.7169 9.4C66.7169 7.55644 66.0592 5.98018 64.7454 4.66474C63.4315 3.35254 61.8488 2.69482 59.9988 2.69482ZM62.0432 11.4461C61.4762 12.0131 60.7926 12.2966 59.9988 12.2966C59.2033 12.2966 58.5197 12.0131 57.9527 11.4461C57.3857 10.8791 57.1006 10.1906 57.1006 9.38542C57.1006 8.59162 57.3857 7.9096 57.9527 7.34098C58.5197 6.77398 59.2033 6.49048 59.9988 6.49048C60.7926 6.49048 61.4746 6.77398 62.0432 7.34098C62.6086 7.9096 62.8953 8.59162 62.8953 9.38542C62.8953 10.1906 62.6086 10.8774 62.0432 11.4461Z"
        fill="#662395"></path>
      <path
        d="M79.2961 4.66474C77.9823 3.35254 76.3995 2.69482 74.5495 2.69482C72.6995 2.69482 71.1216 3.35254 69.8094 4.66474C68.4988 5.98018 67.8459 7.55644 67.8459 9.4C67.8459 11.25 68.4988 12.8295 69.8094 14.1401C71.1216 15.4491 72.6995 16.1036 74.5495 16.1036C76.3995 16.1036 77.9823 15.4491 79.2961 14.1401C80.6099 12.8295 81.2676 11.25 81.2676 9.4C81.2676 7.55806 80.6099 5.98018 79.2961 4.66474ZM76.5956 11.4461C76.0269 12.0131 75.3449 12.2966 74.5511 12.2966C73.7557 12.2966 73.0737 12.0131 72.5067 11.4461C71.9397 10.8791 71.6529 10.1906 71.6529 9.38542C71.6529 8.59162 71.9397 7.9096 72.5067 7.34098C73.0721 6.77398 73.7557 6.49048 74.5511 6.49048C75.3465 6.49048 76.0286 6.77398 76.5956 7.34098C77.1609 7.9096 77.4461 8.59162 77.4461 9.38542C77.4461 10.1906 77.1609 10.8774 76.5956 11.4461Z"
        fill="#FF9600"></path>
      <path
        d="M39.6596 2.69653H35.8509V8.46859L33.0888 3.84511H33.0872C32.6676 3.19711 31.9403 2.76781 31.1108 2.76781C29.81 2.76781 28.757 3.82243 28.757 5.12167C28.757 5.13139 28.7586 5.13949 28.7586 5.14759H28.757V16.1425H32.5656V10.1485L35.4751 15.0183L35.4767 15.0166C35.872 15.6679 36.5864 16.1053 37.4045 16.1053C38.6503 16.1053 39.6596 15.096 39.6596 13.8502C39.6596 13.7968 39.6547 13.7449 39.6515 13.6931H39.6596V2.69653Z"
        fill="#00A1D1"></path>
      <path
        d="M11.6595 2.69336L10.4478 6.56678L10.4397 6.58622L9.53732 9.3062L8.2235 5.35178C7.99994 4.7702 7.68081 4.28096 7.27095 3.87596C6.49173 3.09026 5.55374 2.6966 4.45538 2.6966H0.758545L1.59932 6.49226H4.15893C4.23021 6.49226 4.38086 6.4793 4.49588 6.5684C4.6109 6.6575 4.67246 6.84218 4.67246 6.8438L7.211 14.3525H7.21262C7.50584 15.365 8.43734 16.1053 9.5438 16.1053C10.626 16.1053 11.5413 15.3974 11.8555 14.4189L14.1268 7.64246L15.5767 2.69336H11.6595Z"
        fill="#DC0079"></path>
    </svg>
  )
}
