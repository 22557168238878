import { IIcon } from "./interface"

export const RateIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M16.374 8.24549L12.3406 7.89549L10.7656 4.18716C10.4823 3.51216 9.51563 3.51216 9.23229 4.18716L7.65729 7.90383L3.6323 8.24549C2.89896 8.30382 2.59896 9.22049 3.1573 9.70382L6.21563 12.3538L5.29896 16.2872C5.1323 17.0038 5.90729 17.5705 6.54063 17.1872L9.99896 15.1038L13.4573 17.1955C14.0906 17.5788 14.8656 17.0122 14.699 16.2955L13.7823 12.3538L16.8406 9.70382C17.399 9.22049 17.1073 8.30382 16.374 8.24549ZM9.99896 13.5455L6.86563 15.4372L7.69896 11.8705L4.9323 9.47049L8.58229 9.15383L9.99896 5.79549L11.424 9.16216L15.074 9.47883L12.3073 11.8788L13.1406 15.4455L9.99896 13.5455Z"
        fill="#646464"
      />
    </svg>
  )
}
