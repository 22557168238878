import { IIcon } from "../interface"

export const UploadCV: React.FC<IIcon> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="118" height="118" viewBox="0 0 118 118" className={className}>
      <path
        d="M75.5886 0.911965C75.0475 0.342016 74.3058 0 73.537 0H27.5055C19.0111 0 12 6.9832 12 15.4764V102.523C12 111.017 19.0113 118 27.5052 118H89.9821C98.4763 118 105.488 111.017 105.488 102.523V33.4041C105.488 32.6634 105.145 31.9513 104.661 31.4092L75.5886 0.911965ZM76.4151 10.0616L95.8826 30.4975H83.2275C79.4651 30.4975 76.4151 27.476 76.4151 23.7141V10.0616ZM89.9821 112.3H27.5052C22.1757 112.3 17.7004 107.882 17.7004 102.523V15.4764C17.7004 10.1468 22.1468 5.70041 27.5052 5.70041H70.7147V23.7139C70.7147 30.6397 76.3017 36.1979 83.2275 36.1979H99.7869V102.523C99.7869 107.882 95.3405 112.3 89.9821 112.3Z"
        fill="inherit"
      />
      <path
        d="M81.3203 90.6937H37.6529C36.1942 90.6937 35 91.887 35 93.3469C35 94.8057 36.194 96 37.6529 96H81.3471C82.8058 96 84 94.8059 84 93.3469C84 91.887 82.8058 90.6937 81.3203 90.6937ZM45.9034 62.1456L56.8336 50.3916V79.3645C56.8336 80.8233 58.0278 82.0176 59.4865 82.0176C60.9463 82.0176 62.1396 80.8235 62.1396 79.3645V50.3918L73.0698 62.1456C73.6001 62.7021 74.2906 62.9946 75.0062 62.9946C75.6431 62.9946 76.3066 62.7558 76.8103 62.278C77.8722 61.2699 77.9515 59.5981 76.9435 58.5372L61.3972 41.8487C60.8926 41.3184 60.203 41 59.4605 41C58.7172 41 58.0276 41.3184 57.5241 41.8489L41.9775 58.5372C40.9695 59.5981 41.0491 61.2961 42.1099 62.278C43.2245 63.2863 44.8954 63.2067 45.9034 62.1456Z"
        fill="inherit"
      />
    </svg>
  )
}
