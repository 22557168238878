import { IIcon } from "./interface"

export const AnalyticsIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
      <path
        d="M15.8333 3H4.16667C3.25 3 2.5 3.75 2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V4.66667C17.5 3.75 16.75 3 15.8333 3ZM15.8333 16.3333H4.16667V4.66667H15.8333V16.3333Z"
        fill="#646464"
      />
      <path d="M7.5 10.5H5.83333V14.6667H7.5V10.5Z" fill="#646464" />
      <path d="M14.1667 6.33333H12.5V14.6667H14.1667V6.33333Z" fill="#646464" />
      <path d="M10.8333 12.1667H9.16667V14.6667H10.8333V12.1667Z" fill="#646464" />
      <path d="M10.8333 8.83333H9.16667V10.5H10.8333V8.83333Z" fill="#646464" />
    </svg>
  )
}
