import { IIcon } from "./interface"

export const WaterOutlineIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="13" height="19" viewBox="0 0 13 19" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50017 2.7499C7.11453 3.47761 8.2601 4.90507 9.27552 6.55926C10.3725 8.34637 11.25 10.292 11.25 11.875C11.25 13.3884 10.7762 14.5624 9.98179 15.3568C9.18738 16.1512 8.01338 16.625 6.5 16.625C4.98662 16.625 3.81262 16.1512 3.01821 15.3568C2.2238 14.5624 1.75 13.3884 1.75 11.875C1.75 10.292 2.62747 8.34638 3.72452 6.55927C4.74001 4.90503 5.88569 3.47755 6.50017 2.7499ZM6.06577 1.57674C6.20201 1.51403 6.35021 1.48157 6.50019 1.48157C6.65016 1.48157 6.79836 1.51403 6.9346 1.57674C7.07084 1.63944 7.1919 1.7309 7.28944 1.84481L7.28979 1.84522C7.8866 2.54348 9.16006 4.10125 10.2876 5.93802C11.3996 7.74959 12.4375 9.93886 12.4375 11.875C12.4375 13.641 11.8791 15.1388 10.8215 16.1965C9.76385 17.2541 8.26598 17.8125 6.5 17.8125C4.73402 17.8125 3.23615 17.2541 2.17852 16.1965C1.1209 15.1388 0.5625 13.641 0.5625 11.875C0.5625 9.93886 1.60043 7.74958 2.7125 5.93801C3.84004 4.10124 5.1136 2.54346 5.71064 1.84515L5.71093 1.84481C5.80848 1.73089 5.92953 1.63944 6.06577 1.57674Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76562 11.5781C10.0935 11.5781 10.3594 11.844 10.3594 12.1719C10.3594 13.038 10.0153 13.8686 9.4029 14.481C8.79047 15.0934 7.95985 15.4375 7.09375 15.4375C6.76583 15.4375 6.5 15.1717 6.5 14.8438C6.5 14.5158 6.76583 14.25 7.09375 14.25C7.6449 14.25 8.17348 14.0311 8.56321 13.6413C8.95293 13.2516 9.17188 12.723 9.17188 12.1719C9.17188 11.844 9.43771 11.5781 9.76562 11.5781Z"
      />
    </svg>
  )
}
