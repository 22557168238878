import { IIcon } from "./interface"

export const DoorIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" className={className}>
      <g clipPath="url(#clip0_6771_10034)">
        <path d="M3.6 9.14286C3.91826 9.14286 4.22348 9.02245 4.44853 8.80812C4.67357 8.59379 4.8 8.30311 4.8 8C4.8 7.6969 4.67357 7.40621 4.44853 7.19188C4.22348 6.97755 3.91826 6.85714 3.6 6.85714C3.28174 6.85714 2.97652 6.97755 2.75147 7.19188C2.52643 7.40621 2.4 7.6969 2.4 8C2.4 8.30311 2.52643 8.59379 2.75147 8.80812C2.97652 9.02245 3.28174 9.14286 3.6 9.14286ZM2.4 0C1.76348 0 1.15303 0.240816 0.702944 0.66947C0.252856 1.09812 0 1.67951 0 2.28571V13.7143C0 14.3205 0.252856 14.9019 0.702944 15.3305C1.15303 15.7592 1.76348 16 2.4 16H9.6C10.2365 16 10.847 15.7592 11.2971 15.3305C11.7471 14.9019 12 14.3205 12 13.7143V2.28571C12 1.67951 11.7471 1.09812 11.2971 0.66947C10.847 0.240816 10.2365 0 9.6 0H2.4ZM1.2 2.28571C1.2 1.98261 1.32643 1.69192 1.55147 1.47759C1.77652 1.26327 2.08174 1.14286 2.4 1.14286H9.6C9.91826 1.14286 10.2235 1.26327 10.4485 1.47759C10.6736 1.69192 10.8 1.98261 10.8 2.28571V13.7143C10.8 14.0174 10.6736 14.3081 10.4485 14.5224C10.2235 14.7367 9.91826 14.8571 9.6 14.8571H2.4C2.08174 14.8571 1.77652 14.7367 1.55147 14.5224C1.32643 14.3081 1.2 14.0174 1.2 13.7143V2.28571Z" />
      </g>
      <defs>
        <clipPath id="clip0_6771_10034">
          <rect width="12" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
