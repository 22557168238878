import { IIcon } from "./interface"

export const BoltIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="19" height="40" viewBox="0 0 19 40" fill="#3C74FF" className={className}>
      <g clipPath="url(#clip0_7196_40626)">
        <path
          d="M7.69276 37H5.89368L7.69276 23.7778H1.39597C-0.187216 23.7778 0.802278 22.3611 0.83826 22.3044C3.15907 17.9978 6.64929 11.5756 11.3089 3H13.108L11.3089 16.2222H17.6237C18.3433 16.2222 18.7391 16.5811 18.3433 17.4689C11.2369 30.4833 7.69276 37 7.69276 37Z"
          fill="#3C74FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_7196_40626">
          <rect width="18" height="40" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
