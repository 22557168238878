import { IIcon } from "./interface"

export const ArrowLeftPaginationIcon: React.FC<IIcon> = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M15.5 6.70498C15.11 6.31498 14.48 6.31498 14.09 6.70498L9.50002 11.295C9.11002 11.685 9.11002 12.315 9.50002 12.705L14.09 17.295C14.48 17.685 15.11 17.685 15.5 17.295C15.89 16.905 15.89 16.275 15.5 15.885L11.62 11.995L15.5 8.11498C15.89 7.72498 15.88 7.08498 15.5 6.70498Z"
        fill="#222222"
      />
    </svg>
  )
}
