import { IIcon } from "./interface"

export const SuperVipIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="62" height="28" viewBox="0 0 62 28" fill="none" className={className}>
      <rect width="62" height="28" rx="8" fill="#FD541A" />
      <path
        d="M16.536 8.374C18.818 8.374 20.372 9.578 20.414 11.594L18.216 11.706C18.188 10.824 17.572 10.25 16.578 10.25C15.584 10.25 15.024 10.768 15.024 11.51C15.024 12.308 15.64 12.49 17.082 12.826C19.21 13.358 20.638 14.086 20.638 15.948C20.638 18.174 18.888 19.154 16.774 19.154C14.366 19.154 12.616 17.964 12.588 15.794L14.786 15.682C14.814 16.634 15.654 17.278 16.774 17.278C17.754 17.278 18.426 16.928 18.426 16.074C18.426 15.276 17.684 15.038 16.27 14.688C14.38 14.212 12.826 13.61 12.826 11.58C12.826 9.858 14.044 8.374 16.536 8.374ZM21.7892 14.1H26.2132V16.004H21.7892V14.1ZM34.0855 8.528H36.4515L32.0415 19H30.2215L25.8115 8.528H28.1635L31.1315 15.668L34.0855 8.528ZM37.4396 8.528H39.6096V19H37.4396V8.528ZM41.6232 8.528H46.1592C48.1472 8.528 49.5332 9.746 49.5332 11.902C49.5332 14.058 48.1472 15.276 46.1592 15.276H43.7932V19H41.6232V8.528ZM45.8792 10.432H43.7792V13.358H45.8792C46.7192 13.358 47.3352 12.854 47.3352 11.902C47.3352 10.95 46.7192 10.432 45.8792 10.432Z"
        fill="white"
      />
    </svg>
  )
}
