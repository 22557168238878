import { IIcon } from "./interface"

export const LightBulbIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg width="24" height="40" viewBox="0 0 24 40" fill="#222222" className={className}>
      <path
        d="M6.99967 35C6.99967 35.8333 7.66634 36.6666 8.66634 36.6666H15.333C16.333 36.6666 16.9997 35.8333 16.9997 35V33.3333H6.99967V35ZM11.9997 3.33331C5.49967 3.33331 0.333008 8.49998 0.333008 15C0.333008 19 2.33301 22.5 5.33301 24.5V28.3333C5.33301 29.1666 5.99967 30 6.99967 30H16.9997C17.9997 30 18.6663 29.1666 18.6663 28.3333V24.5C21.6663 22.3333 23.6663 18.8333 23.6663 15C23.6663 8.49998 18.4997 3.33331 11.9997 3.33331Z"
        fill="#222222"
      />
    </svg>
  )
}
