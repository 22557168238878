import { IIcon } from "./interface"

export const PaginationLastIcon: React.FC<IIcon> = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M6.79152 8.11L10.6815 12L6.79152 15.89C6.40152 16.28 6.40152 16.91 6.79152 17.3C7.18152 17.69 7.81152 17.69 8.20152 17.3L12.7915 12.71C13.1815 12.32 13.1815 11.69 12.7915 11.3L8.20152 6.7C7.81152 6.31 7.18152 6.31 6.79152 6.7C6.41152 7.09 6.41152 7.73 6.79152 8.11ZM17.5015 6C18.0515 6 18.5015 6.45 18.5015 7V17C18.5015 17.55 18.0515 18 17.5015 18C16.9515 18 16.5015 17.55 16.5015 17V7C16.5015 6.45 16.9515 6 17.5015 6Z"
        fill="#222222"
      />
    </svg>
  )
}
